/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { makeStyles } from '@rentacenter/racstrap';
export const fieldDeviceStyles = () => {
  const useClasses = makeStyles((theme) => ({
  
    [theme.breakpoints.between(800, 961)]: {
    //display styles starts 
    dMdNone: {
        display: 'none !important',
      },
      dMdBlock: {
        display: 'block !important',
      },
      dMdFlex: {
        display: 'flex !important',
      },
      alignCenter:
      {
        alignItems:'center'
      },
      //display styles ends 
      
    },
    [theme.breakpoints.between(599, 801)]: {
    //display styles starts 
    dMdNone: {
        display: 'none !important',
      },
      dMdBlock: {
        display: 'block !important',
      },
      dMdFlex: {
        display: 'flex !important',
      },
      //display styles ends 
     

    },
    positionRelative:
    {
      position: 'relative'
    },
    positionAbsolute:
    {
      position: 'absolute'
    },
    hide:{
     display:"none"
    },
    me3:
    {
      marginLeft:'16px'
    },
    ms4:
    {
      marginLeft:'24px'
    },
    mt2:{
      marginTop:'12px'
    },
    pt0:{
      paddingTop: '0'
    },
    pb0:{
      paddingBottom: '0'
    },
    p3:{
      padding:'16px'
    },
    justifyContentEnd:
    {
      justifyContent:'end',
      display: 'flex',
    },
    activeExpand:{
      color: '#ffffff !important',
      background: '#438fff !important',
      borderRadius: '5px',
      '&:after':{
        borderColor:'#fff !important',
      },
    },
    menuHeader:{
      display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    },
    fixedTop:{
      position:'fixed',
      top:0,
      background:'#fff',
      right:'0',
     zIndex:1,
     width:'300px'
    }
    }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};