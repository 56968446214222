import React from 'react';
import ReactDOM from 'react-dom';
import * as Reactstrap from 'reactstrap';
import { datadogRum } from '@datadog/browser-rum';
import { getEnvValue } from './utils/utils';

import { App } from './app/App';
import './index.css';

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}
const envVar = getEnvValue();
if (
  envVar === 'dev' ||
  envVar === 'qa' ||
  envVar === 'uat' ||
  envVar === 'perf' ||
  envVar === 'training' ||
  envVar === 'prod'
) {
  datadogRum.init({
    applicationId: 'd2c342e2-07ef-4185-9446-bb3ade29c721',
    clientToken: 'pub31c376da00d50fcd551c38c2d2bded3a',
    site: 'datadoghq.com',
    service: 'racpad-ui',
    env: envVar,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    sampleRate: 100,
    replaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*-.*racpad\.rentacenter\.com/],
  });
}

window.React = React;
window.ReactDOM = ReactDOM;
window.Reactstrap = Reactstrap;
window.isRenderedByContainer = true;

ReactDOM.render(<App />, document.getElementById('root'));
