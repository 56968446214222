import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';
export const payment1TestId = 'payment1TestId';

export const Payment1 = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  return (
    <div className="racMicroFrontEnds" data-testid={payment1TestId}>
      <MicroFrontend
        config={appConfig.microfrontends.payment1}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
