import { UserActionType, CurrentuserAction } from './user-actions';
import { CurrentUser } from '../global-reducer';

export const userReducer = (
  state: CurrentUser,
  action: CurrentuserAction
): CurrentUser => {
  switch (action.type) {
    case UserActionType.GET_USER:
      return {
        user: undefined,
        isPending: true,
        error: undefined,
      };

    case UserActionType.GET_USER_SUCCESS:
      return {
        user: action.payload.user,
        isPending: false,
        error: undefined,
      };

    case UserActionType.GET_USER_ERROR:
      return {
        user: undefined,
        isPending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
