/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@rentacenter/racstrap';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';

export const testId = 'reportsTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '3rem',
  },
}));

export const CashManagement = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  const classes = useStyles();
  useEffect(() => {
    console.log(
      'Inside Cash Management',
      appConfig.microfrontends.cashMgmt,
      history
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.microAppContainer} data-testid={testId}>
      <MicroFrontend
        config={appConfig.microfrontends.cashMgmt}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
