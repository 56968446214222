import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { makeStyles } from '@rentacenter/racstrap';

export const testId = 'configurationsTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '5rem',
  },
}));

export const Configurations = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  return (
    <div className={classes.microAppContainer} data-testid={testId}>
      <MicroFrontend
        config={appConfig.microfrontends.configurations}
        history={history}
      />
    </div>
  );
};
