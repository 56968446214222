/* eslint-disable prettier/prettier */
export enum RoutePath {
  Index = "/",
  Dashboard = "/dashboard",
  Payment = "/payment",
  Payment1 = "/payment1",
  AccountManagement = "/am",
  Reports = "/reports",
  Reporting = "/reporting",
  Customer = "/customer",
  Inventory = "/inventory",
  Store = "/storemgmt",
  Agreement = "/agreement",
  ManualPO = "/manual-po",
  ManualPORedesign = "/manualpo",
  Configurations = "/configs",
  DailyActivityPlanner = "/dap",
  Settings = "/settings",
  NotFound = "/not-found",
  DOF = "/digitalorderform",
  Pricing = "/pricing",
  ReceivingPO = "/receivingpo",
  Drop = "/cashmgmt",
  NewManualPOPath = "/mpo",
  PORevePath = "/receivingpo/purchaseorders?rev",
  AcimaPO='/acimapo/poreceive',
  OpsMgmt='/operations',
  finance='/finance',
  taxmgmt='/taxmgmt'
}

export enum RouteName {
  Index = "Dashboard",
  Payment = "Payment",
  AccountManagement = "Account Management",
  Reports = "Reports",
  Reporting = "Reporting",
  Customer = "Customer",
  Inventory = "Inventory",
  Store = "Store",
  Agreement = "Agreement",
  Dashboard = "Dashboard",
  Pricing = "Pricing",
  Operations = "Operations",
  ManualPO = "Manual Purchase Order",
  Configurations = "Admin",
  DailyActivityPlanner = "Daily Activity Planner",
  Settings = "Settings",
  HelpCenter = "Help Center",
  DOF = "Send Digital Order Form",
  ReceivingPO = "PO Receiving",
  Drop = "Drops",
  Admin = "Admin",
  NewManualPO = "MPO",
  POReverse = "PO Reverse",
  AcimaPO = "PO Reverse",
  OpsMgmt = "Operations",
  finance = "Finance",
  taxMgmt = 'Tax Exemption'
}
