/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-identical-functions */

import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  List,
  ListItem,
  Typography,
  RACSearchSelect,
  RACButton,
  SwipeableDrawer,
  Box,
} from '@rentacenter/racstrap';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger-menu.svg';
import { ReactComponent as CanvasClose } from '../../assets/images/canvasCloseIcon.svg';
import 'react-phone-number-input/style.css';
import { NavLink, useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { headerStyles } from '../../headerStyles';
import { fieldDeviceStyles } from '../../fieldDeviceSty';
import { UserContext } from '../../app/authenticated/Authenticated';
import { appConfig } from '../../config/app-config';
import { RouteName } from '../../config/route-config';
import { AllowedSubRoute } from '../../app/authenticated/routes/getAllowedSubRoutes';
import { GlobalSearch } from '../GlobalSearch/globalSearch';
import { HelperComponent } from '../Helper/helperComponent';
import { UserComponent } from '../UserInfo/userComponent';

const manualpo = appConfig.microfrontends.manualPO.url.includes('manual-po')
  ? 'manual-po'
  : 'manualpo';

const AccountManagement = 'Account Management';

const manualPOSubMenu = {
  menu: 'Inventory',
  submenu: 'Manual PO',
  categories: 'Inventory',
  path: appConfig.microfrontends.manualPO.url.includes('manual-po')
    ? '/manual-po'
    : '/manualpo',
};

const InventoryPoSubmenu = [
  {
    menu: 'Inventory',
    submenu: 'Search Inventory',
    categories: 'Inventory',
    path: '/inventory/inventorysearch',
  },
  {
    menu: 'Inventory',
    submenu: 'Idle Inventory Audit',
    categories: 'Inventory',
    path: '/inventory/audit/history',
  },
  {
    menu: 'Inventory',
    submenu: 'Print-Edit Price Tags',
    categories: 'Inventory',
    path: '/inventory/printeditpricetag',
  },
  {
    menu: 'Inventory',
    submenu: 'New Package Item Search',
    categories: 'Inventory',
    path: '/inventory/packagepricetag',
  },
  {
    menu: 'Inventory',
    submenu: 'Inventory Transfer Receive',
    categories: 'Inventory',
    path: '/inventory/inventorytransfer',
  },
  {
    menu: 'Inventory',
    submenu: 'Items Being Serviced',
    categories: 'Inventory',
    path: '/inventory/itembeingserviced',
  }
];

const receivePOSubMenu = {
  menu: 'Inventory',
  submenu: 'PO Receiving',
  categories: 'Purchase Order',
  path: '/receivingpo/purchaseorders',
};
const newManPo = {
  menu: 'Inventory',
  submenu: 'Manual PO',
  categories: 'Purchase Order',
  path: '/mpo',
};
const poReverse = {
  menu: 'Inventory',
  submenu: 'PO Reverse',
  categories: 'Purchase Order',
  path: '/receivingpo/purchaseorders?rev',
};

const paymentSubMenu = {
  menu: 'Payment',
  submenu: 'Take Payment',
  categories: 'Payment',
  path: '/payment1/paymentsearch',
};

const closeEOD = {
  menu: 'Store',
  submenu: 'Close End of Day',
  categories: 'Store',
  path: '/storemgmt/closeeod',
};
const lookupstore = {
  menu: 'Store',
  submenu: 'Lookup Store',
  categories: 'Store',
  path: '/storemgmt/lookupstore',
};
const pinManagement = {
  menu: 'Store',
  submenu: 'PIN Management',
  categories: 'Store',
  path: '/storemgmt/pinmanagement',
};
const routeAssignment = {
  menu: 'Store',
  submenu: 'Route Assignment',
  categories: 'Store',
  path: '/storemgmt/routeassignment',
};
const dapEvents = {
  menu: 'Store',
  submenu: 'DAP Events',
  categories: 'Store',
  path: '/storemgmt/dap',
};
const submenuTab = [
  {
    menu: 'Payment',
    submenu: 'RAC Pay',
    categories: 'Payment',
    path: '/payment',
  },
  {
    menu: 'Customer',
    submenu: 'Create Customer',
    categories: 'Customer',
    path: '/customer/createcustomer',
  },
  {
    menu: 'Customer',
    submenu: 'Create Agreement',
    categories: 'Agreement',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    path: '/customer/searchcustomer',
  },
  {
    menu: 'Customer',
    submenu: 'Agreement Transfer Receive',
    categories: 'Agreement',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    path: '/agreement/transferreceive',
  },
  {
    menu: AccountManagement,
    submenu: 'Past Due List',
    categories: 'Collections',
    path: '/am/past-due-list',
  },
  {
    menu: AccountManagement,
    submenu: 'AM Activity Log',
    categories: 'Collections',
    path: '/am/activity-log',
  },
  {
    menu: 'Store',
    submenu: 'Daily Activity Planner',
    categories: 'Store',
    path: '/dap',
  },
  {
    menu: 'Store',
    submenu: 'Send Digital Order Form',
    categories: 'Store',
    path: '/storemgmt/digitalorderform',
  },
  {
    menu: 'Operations',
    submenu: 'Pricing Uploads',
    categories: 'Pricing',
    path: '/pricing/upload',
  },
  {
    menu: 'Operations',
    submenu: 'Reports',
    categories: 'Pricing',
    path: '/pricing/report',
  },
  {
    menu: 'Admin',
    submenu: 'Field Support Center',
    categories: 'Admin',
    path: '/storemgmt/fsc/details',
  },
  {
    menu: 'Admin',
    submenu: 'Contest',
    categories: 'Admin',
    path: '/storemgmt/contest/details',
  },
];

const cashManagementMenu = [
  {
    menu: 'Store',
    submenu: 'Drops',
    categories: 'Cash Management',
    path: '/cashmgmt/drop',
  },
  {
    menu: 'Store',
    submenu: 'Final Drop',
    categories: 'Cash Management',
    path: '/cashmgmt/final/drop',
  },
  {
    menu: 'Store',
    submenu: 'Drop Reconciliation',
    categories: 'Cash Management',
    path: '/cashmgmt/reconcil',
  },
  {
    menu: 'Store',
    submenu: 'Drawer Audit',
    categories: 'Cash Management',
    path: '/cashmgmt/audit',
  },
  {
    menu: 'Store',
    submenu: 'Cash Paid Out of Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidout',
  },
  {
    menu: 'Store',
    submenu: 'Paid In to Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidin',
  },
  {
    menu: 'Store',
    submenu: 'Cash Drop Log',
    categories: 'Cash Management',
    path: '/cashmgmt/cashdrop',
  },
  {
    menu: 'Store',
    submenu: 'Past Drop Receipt',
    categories: 'Cash Management',
    path: '/cashmgmt/pastdrop',
  },
];

//eslint-disable-next-line sonarjs/cognitive-complexity
export const Header = (props: any | undefined) => {
  const classes = headerStyles();
  const { activeStore }: any = useContext(UserContext);
  const [value, setValue] = useState(activeStore);
  const [hideShowForm, setStyle] = useState('');
  const [menuClick, setsubmenu] = useState('');
  const [DivActive, setDivActive] = useState('0');
  const [SubMenuActive, setSubMenuActive] = useState('');
  const [HoverOption, setHoverOption] = useState('');
  const [menuTab, setMenuTab] = useState([]);
  const [RacIconNav, setRacIconNav] = useState('/dashboard');
  const [dropdown, setstoreDropdown] = useState<any>();
  const history = useHistory();
  const path = window.location.pathname.split('/');
  const acctmgmtPath = path[2] == 'am' ? 'account management' : '';
  const poReceivingPath = path[2] == 'receivingpo' ? 'inventory' : '';
  const manualPOPath = path[2] == manualpo ? 'inventory' : '';
  const inventoryPath = path[2] == 'inventory' ? 'inventory' : '';
  const dapPath = path[2] == 'dap' ? 'store' : '';
  const dofPath =
    path[2] == 'storemgmt' && path[3] !== 'fsc' && path[3] !== 'contest'
      ? 'store'
      : '';
  const agreementPath = path[2] == 'agreement' ? 'customer' : '';
  const payment1Path = path[2] == 'payment1' ? 'payment' : '';
  const pricingPath = path[2] == 'pricing' ? 'operations' : '';
  const CashMgmtPath = path[2] == 'cashmgmt' ? 'store' : '';
  const fscPath = path[3] == 'fsc' ? 'admin' : '';
  const contestPath = path[3] == 'contest' ? 'admin' : '';

  const [subMenuTab, setSubMenuTab] = useState(submenuTab);
  const [isAnchorEnabled, setIsAnchorEnabled] = useState({ right: false });
  const [verticalMenuVisibleValues, setVerticalMenuVisibleValues]: any =
    useState([]);
  const [subMenuClicked, setSubMenuClicked] = useState(false);
  type Anchor = 'right';
  const classes1 = fieldDeviceStyles();

  useEffect(() => {
    setMenuTab(props.passingData);
    //eslint-disable-next-line sonarjs/no-duplicate-string
    setstoreDropdown(props.dropDownData ? props.dropDownData : []);

    if (
      props !== undefined &&
      props.passingData.length > 0 &&
      props.passingData[0].name === RouteName.Payment
    ) {
      console.log('racnav:payement');
      setRacIconNav('/payment1');
    } else if (props !== undefined && props.passingData.length > 0) {
      console.log('racnav: ', props.passingData[0].path);
      setRacIconNav(props.passingData[0].path);
      const menuLengthArray: any = new Array(props.passingData.length);
      menuLengthArray.fill(false);
      setVerticalMenuVisibleValues(menuLengthArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.passingData]);
  // eslint-disable-next-line sonarjs/cognitive-complexity

  useEffect(() => {
    const allowedSubRoutes: AllowedSubRoute[] = props.allowedSubRoutes;
    if (allowedSubRoutes && allowedSubRoutes.length > 0) {
      const updatedSubMenu = [...submenuTab];

      const hasPayment = allowedSubRoutes.find(
        (item) => item.path === '/payment1',
      );
      const hasInventory = allowedSubRoutes.find(
        (item) => item.name === RouteName.Inventory,
      );
      const hasManualPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.ManualPO,
      );
      const hasReceivingPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.ReceivingPO,
      );

      if (hasInventory) {
        InventoryPoSubmenu.map((value) => {
          updatedSubMenu.push(value);
        });
      }
      const hasRevPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.NewManualPO,
      );
      const hasNewManualPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.POReverse,
      );

      hasPayment && updatedSubMenu.unshift(paymentSubMenu);
      hasManualPO && updatedSubMenu.push(manualPOSubMenu);
      hasRevPO && updatedSubMenu.push(newManPo);
      hasReceivingPO && updatedSubMenu.push(receivePOSubMenu);
      hasNewManualPO && updatedSubMenu.push(poReverse);

      const acimaPoPermission = props.menuNavPermissions.filter((el: any) => {
        return el == '`RPDACIMAPO`';
      });
      if(acimaPoPermission?.length > 0){
        updatedSubMenu.push({
          menu: 'Inventory',
          submenu: 'Acima PO Receiving',
          categories: 'Purchase Order',
          path: '/acimapo/poreceive',
        });
      }
      
      /* eslint-disable no-console */
      console.log('Nav cashmgmt', props);
      if (
        props.menuNavPermissions !== undefined &&
        props.menuNavPermissions !== null &&
        props.menuNavPermissions !== '' &&
        props.menuNavPermissions.length > 0
      ) {
        console.log('Nav props.menuNavPermissions', props.menuNavPermissions);
        const menuCashmgmt = props.menuNavPermissions.filter((el: any) => {
          return el == 'RPDCASHMGMT';
        });
        menuCashmgmt.length > 0
          ? cashManagementMenu.map((value) => {
              updatedSubMenu.push(value);
            })
          : '';
      }
      const menuCloseEOD = props.menuNavPermissions.filter((el: any) => {
        return el == 'RPDCLOSEEOD';
      });
      const menuLookUpStore = props.menuNavPermissions.filter((el: any) => {
        return el == 'RPDLOOKUPSTORE';
      });
      const menuRouteAssignment = props.menuNavPermissions.filter((el: any) => {
        return el == 'RPDROUTEASSIGNMENT';
      });
      const menupinManagement = props.menuNavPermissions.filter((el: any) => {
        return el == 'RPDPINMANAGEMENT';
      });
      const menuDapEvents = props.menuNavPermissions.filter((el: any) => {
        return el == 'RPDDAPEVENTS';
      });
      if (menuRouteAssignment.length > 0) {
        updatedSubMenu.push(routeAssignment);
      }
      if (menuLookUpStore.length > 0) {
        updatedSubMenu.push(lookupstore);
      }
      if (menupinManagement.length > 0) {
        updatedSubMenu.push(pinManagement);
      }
      if (menuCloseEOD.length > 0) {
        updatedSubMenu.push(closeEOD);
      }
      if (menuDapEvents.length > 0) {
        updatedSubMenu.push(dapEvents);
      }
      // validate the menu and add the inventory menu items
      // console.log('Nav cashmgmt', props);
      // if (
      //   props.menuNavPermissions !== undefined &&
      //   props.menuNavPermissions !== null &&
      //   props.menuNavPermissions !== '' &&
      //   props.menuNavPermissions.length > 0
      // ) {
      //   console.log('Nav props.menuNavPermissions', props.menuNavPermissions);
      //   const menuFSC = props.menuNavPermissions.filter((el: any) => {
      //     return el == 'RPDFSC';
      //   });
      //   const menuContest = props.menuNavPermissions.filter((el: any) => {
      //     return el == 'RPDFSC';
      //   });
      //   const menuConfig = props.menuNavPermissions.filter((el: any) => {
      //     return el == 'OWNRCNFG';
      //   });
      //   menuFSC.length > 0 ? updatedSubMenu.push(fscSubmenu) : '';
      //   menuContest.length > 0 ? updatedSubMenu.push(contestSubmenu) : '';
      //   menuConfig.length > 0 ? updatedSubMenu.push(ownerConfigSubmenu) : '';
      // }

      setSubMenuTab(updatedSubMenu);
      /* eslint-disable no-console */
      console.log('Nav menu manualPOSubMenu', updatedSubMenu, hasInventory);
      console.log('Nav menu Allowed', allowedSubRoutes, props);
      /* eslint-enable no-console */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowedSubRoutes]);

  useEffect(() => {
    if (dropdown !== undefined && dropdown.length > 0) {
      const currentStore = sessionStorage.getItem('storeNumber');
      for (let i = 0; i < dropdown.length; i++) {
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (currentStore != null && currentStore != undefined) {
          if (dropdown[i].accountingUnitNumber == currentStore) {
            break;
          }
        } else {
          sessionStorage.setItem(
            'storeNumber',
            dropdown[0].accountingUnitNumber,
          );
          sessionStorage.setItem(
            'storeDetails',

            Buffer.from(JSON.stringify(dropdown[0].storeDetails)).toString(
              'base64',
            ),
          );
        }
      }
    }
  }, [dropdown]);

  const searchForm = () => {
    hideShowForm == '' ? setStyle('show') : setStyle('');
    setSubMenuActive('');
  };

  useEffect(() => {
    document.getElementById('lastnametextbox')?.focus();
    if(subMenuClicked){
      setSubMenuClicked(false)
    }
  }, [hideShowForm,subMenuClicked]);

  const submenuDisplay = (e: any, group: any) => {
    menuClick == '' ? setsubmenu('show') : setsubmenu('');
    const DivID = e.target.id;
    // eslint-disable-next-line no-console
    console.log('Test Menu Details', DivID, e, group);
    if (DivID !== DivActive || group.length === 0) {
      setDivActive(DivID);
      setSubMenuActive(DivID);
    } else {
      setDivActive('');
      setSubMenuActive('');
    }
    setStyle('');
    submenudisplaynone();
  };

  const submenudisplaynone = () => {
    setSubMenuActive('');
    setDivActive('');
  };

  const SubMenuOptionClick = () => {
    const DivId = HoverOption;
    setSubMenuActive('');
    setDivActive(DivId);
    // eslint-disable-next-line no-console
    console.log('Inside SubMenu Option', DivId);
  };

  const storeDisplay = (e: any) => {
    const storeNum = e.target.innerText.split(' ');
    if (storeNum == undefined || storeNum[2] == undefined) {
      setValue('');
    } else {
      const storeIndex = dropdown.findIndex(
        (e: any) => e.accountingUnitNumber == storeNum[2],
      );
      setValue(storeNum[2]);
      sessionStorage.setItem('storeNumber', storeNum[2]);

      sessionStorage.setItem(
        'storeDetails',
        Buffer.from(JSON.stringify(dropdown[storeIndex].storeDetails)).toString(
          'base64',
        ),
      );
      history.push({
        pathname: RacIconNav,
      });
    }
    // setstoreNumber(storeNum[2]);
  };

  const submenuDisplayOnMouseOver = (e: any) => {
    const HoverID = e.target.id;
    // eslint-disable-next-line no-console
    console.log('Inside hoverid function', HoverID);
    setHoverOption(HoverID);
  };

  const searchOutsideClick = () => {
    setStyle('');
  };

  const bindDropDown = () => {
    return (
      <Grid
        className={`${classes.racDropdown} ${
          dropdown.length !== 1 ? classes.dropWidth : null
        }`}
      >
        <OutsideClickHandler onOutsideClick={() => (null)}>
          {dropdown.length !== 1 ? (
            <RACSearchSelect
              options={dropdownStrap()}
              backgroundColorCondition={true}
              backgroundColor="#f2f2ff"
              inputRootClass={classes.dropheight}
              value={
                window.sessionStorage.getItem('storeNumber') !== undefined &&
                window.sessionStorage.getItem('storeNumber') !== null &&
                window.sessionStorage.getItem('storeNumber') !== ''
                  ? `Store - ${window.sessionStorage.getItem('storeNumber')}`
                  : value != ''
                  ? `Store - ${value}`
                  : ''
              }
              onChange={(e) => storeDisplay(e)}
              rootClass={classes.DropBorder}
              inputClass={classes.textFont}
              IconClassName={classes.IconClass}
              optionClass={classes.dropdownitemstyle}
              onKeyDown={(event) => (event.defaultMuiPrevented = true)}
            ></RACSearchSelect>
          ) : (
            <Typography className={classes.singleStore}>
              Store - {dropdown[0].accountingUnitNumber}
            </Typography>
          )}
        </OutsideClickHandler>
      </Grid>
    );
  };

  const dropdownStrap = () => {
    if (dropdown !== undefined) {
      return dropdown.map((obj: any) => {
        return {
          label: 'Store - ' + obj.accountingUnitNumber,
          value: obj.accountingUnitNumber,
        };
      });
    }
  };

  const nodataBind = () => {
    return (
      <Grid className={classes.racDropdown}>
        <Typography className={classes.singleStore}>No Stores</Typography>
      </Grid>
    );
  };

  function groupBy(objectArray: any, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsAnchorEnabled({ ...isAnchorEnabled, [anchor]: open });
    };
  const verticalMenuOptionClick = (e: any) => {
    const indexOfMenu = e.target.id;
    const menuVisibleArray = new Array(verticalMenuVisibleValues.length);
    menuVisibleArray.fill(false);
    if (!verticalMenuVisibleValues[indexOfMenu]) {
      menuVisibleArray[indexOfMenu] = true;
    }
    setVerticalMenuVisibleValues(menuVisibleArray);
  };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Box>
  );

  return (
    <div className="App">
      <Grid
        classes={{ root: clsx(classes.racNavbarExpand, classes.racFixedTop) }}
      >
        <Grid className={classes.racContainerFluid}>
          {/*RAC LOGO*/}
          <Link to={RacIconNav} className={classes.racNavBarBrand}>
            <RACLogo className={classes.racLogoResponsive}></RACLogo>
          </Link>
          {/*RAC LOGO ENDS*/}
          <Grid
            classes={{
              root: clsx(classes.racNavbarCollapse, classes.ContentEnd),
            }}
            id="navbarSupportedContent"
          >
            {/*Menu Items Starts Here*/}
            {props.passingData !== undefined ? (
              <List className={`${classes.racNavbarNav} ${classes1.dMdNone}`}>
                {menuTab.map((value: any, index: any) => {
                  const group = subMenuTab.filter(
                    (data: any) =>
                      data.menu ===
                      (value.name === 'Agreement'
                        ? 'Customer'
                        : value.name == 'Reporting'
                        ? 'Reports'
                        : value.name),
                  );
                  const subgroup = groupBy(group, 'categories');
                  const category = Object.keys(subgroup);
                  return (
                    <ListItem key="menu" className={`${subMenuClicked ? classes.racNavBarNone : classes.racNavBar}`}>
                      {group.length === 0 ? (
                        <NavLink
                          id={index.toString()}
                          activeClassName={classes.racNavMenuActive}
                          className={classes.racNavMenu}
                          to={value.path.toLowerCase()}
                          onMouseOver={(e: any) => submenuDisplayOnMouseOver(e)}
                          onClick={(e: any) => submenuDisplay(e, group)}
                        >
                          {value.name === 'Agreement'
                            ? 'Customer'
                            : value.name == 'Reporting'
                            ? 'Reports'
                            : value.name}
                        </NavLink>
                      ) : (
                        <NavLink
                          to="#"
                          id={index.toString()}
                          activeClassName={clsx({
                            [classes.racNavMenuActive]:
                              path[2] == value.name.toLowerCase() ||
                              acctmgmtPath == value.name.toLowerCase() ||
                              poReceivingPath == value.name.toLowerCase() ||
                              manualPOPath == value.name.toLowerCase() ||
                              dapPath == value.name.toLowerCase() ||
                              dofPath == value.name.toLowerCase() ||
                              pricingPath == value.name.toLowerCase() ||
                              agreementPath == value.name.toLowerCase() ||
                              inventoryPath == value.name.toLowerCase() ||
                              payment1Path == value.name.toLowerCase() ||
                              CashMgmtPath == value.name.toLowerCase() ||
                              fscPath == value.name.toLowerCase() ||
                              contestPath == value.name.toLowerCase(),
                          })}
                          onClick={(e) => submenuDisplay(e, group)}
                          onMouseOver={(e) => submenuDisplayOnMouseOver(e)}
                          onBlur={() => submenudisplaynone()}
                          className={classes.racDropdownToggle}
                        >
                          {' '}
                          {value.name === 'Agreement'
                            ? 'Customer'
                            : value.name == 'Reporting'
                            ? 'Reports'
                            : value.name}
                        </NavLink>
                      )}
                      {group.length !== 0 ? (
                        <Grid
                          id={index.toString()}
                          classes={{
                            root: clsx(
                              classes.racDropdownMenu,
                              classes.racMenuContent,
                            ),
                          }}
                          className={clsx({
                            [classes.displaySubmenu]:
                              SubMenuActive === index.toString(),
                          })}
                          aria-labelledby="navbarpayment"
                        >
                          <Grid className={classes.racMegaMenu}>
                            <Grid container>
                              {category.map((data) => {
                                const filteredGroup = group.filter(
                                  (result:any) => result.categories == data,
                                );
                                const categoryLength = category.length;
                                const submenuLength = filteredGroup.length;
                                // console.log('logger----->', data, categoryLength, submenuLength)
                                // {
                                //   AssignClassName(
                                //     categoryLength,
                                //     submenuLength
                                //   );
                                // }
                                return (
                                  <Grid
                                    key=""
                                    className={clsx({
                                      [classes.racColMD12]: categoryLength <= 1,
                                      [classes.racColMD6]:
                                        (categoryLength == 2 &&
                                          submenuLength == 2) ||
                                        (categoryLength == 2 &&
                                          submenuLength == 1),
                                      [classes.racColMD2]:
                                        categoryLength == 4 &&
                                        submenuLength == 3,
                                      [classes.racColMD4]:
                                        categoryLength == 4 &&
                                        submenuLength == 6,
                                      [classes.racColMD3]:
                                        categoryLength == 4 &&
                                        submenuLength == 2,
                                      [classes.racColMD7]:
                                        categoryLength == 2 &&
                                        submenuLength == 6,
                                      [classes.racColMD7]:
                                        categoryLength == 2 &&
                                        submenuLength == 3,
                                      [classes.racColMD7]:
                                        categoryLength == 2 &&
                                        submenuLength >= 3,
                                    })}
                                  >
                                    <span className={classes.menuSubHeader}>
                                      {data}
                                    </span>

                                    <Grid container className="">
                                      {filteredGroup.map((val, ind) => {
                                        return (
                                          <Grid
                                            item
                                            key=""
                                            className={clsx({
                                              [classes.racColMD3]:
                                                categoryLength <= 1,
                                              [classes.racColMD6]:
                                                (categoryLength == 2 &&
                                                  submenuLength == 2) ||
                                                (categoryLength == 2 &&
                                                  submenuLength == 1),
                                              [classes.racColMD12]:
                                                categoryLength == 4 &&
                                                submenuLength == 3,
                                              [classes.racColMD6]:
                                                categoryLength == 4 &&
                                                submenuLength == 6,
                                              [classes.racColMD12]:
                                                categoryLength == 4 &&
                                                submenuLength == 2,
                                              [classes.racColMD4]:
                                                categoryLength == 2 &&
                                                submenuLength == 3,
                                              [classes.racColMD4]:
                                                categoryLength == 2 &&
                                                submenuLength >= 3,
                                            })}
                                          >
                                            <Link
                                              id={ind.toString()}
                                              onClick={() =>
                                                SubMenuOptionClick()
                                              }
                                              className={classes.navMenu}
                                              to={val.path || '#'}
                                            >
                                              {val.submenu}
                                            </Link>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </ListItem>
                  );
                })}
              </List>
            ) : null}
            <Grid className={classes.dFlex}>
              {path[3] !== 'searchcustomer' ? (
                <Link
                  to="#"
                  data-testid="searchIcon"
                  className={clsx({
                    [classes.racGlobalSearchBtnShow]: hideShowForm === 'show',
                    [classes.racGlobalSearchBtn]: hideShowForm === '',
                    [classes.cursorDefault]:
                      path[2] == 'customer' && path[3] == 'searchcustomer',
                  })}
                  id="navbarglobalsearch"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={searchForm}
                ></Link>
              ) : null}
              <GlobalSearch
                hideShowForm={hideShowForm}
                searchOutsideClick={searchOutsideClick}
              />
              {dropdown !== undefined && dropdown.length > 0
                ? bindDropDown()
                : nodataBind()}
              <HelperComponent throwDropdownValue={dropdown}/>
              <UserComponent userData={props.userData} />
              <Grid className={`${classes1.dMdBlock} ${classes1.hide} `}>
                {(['right'] as const).map((anchor) => (
                  <React.Fragment key={anchor}>
                    <RACButton
                      endIcon={<Hamburger />}
                      onClick={toggleDrawer(anchor, true)}
                    ></RACButton>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={isAnchorEnabled[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      <Grid
                        className={`${classes1.justifyContentEnd} ${classes1.fixedTop}`}
                      >
                        <RACButton
                          onClick={toggleDrawer(anchor, false)}
                          endIcon={<CanvasClose />}
                        ></RACButton>
                      </Grid>
                      {/* dynamic binding starts here */}
                      <List>
                        <ListItem className={`${classes1.mt2}`}>
                          <Link
                            to={'/dashboard/homepage'}
                            className={`${classes.racNavMenu}`}
                            onClick={() => {
                              setIsAnchorEnabled({ right: false });
                            }}
                          >
                            Dashboard
                          </Link>
                        </ListItem>
                        {menuTab.map((value: any, index: any) => {
                          const group = subMenuTab.filter(
                            (data: any) =>
                              data.menu ===
                              (value.name === 'Agreement'
                                ? 'Customer'
                                : value.name == 'Reporting'
                                ? 'Reports'
                                : value.name),
                          );
                          const menuOptions = groupBy(group, 'menu');
                          const menu = Object.keys(menuOptions);
                          const subgroup = groupBy(group, 'categories');
                          const category = Object.keys(subgroup);
                          return (
                            <>
                              {['Reporting', 'Reports'].includes(value.name) ? (
                                <ListItem className={`${classes1.mt2}`}>
                                  <Link
                                    to={value.path}
                                    className={`${classes.racNavMenu}`}
                                    onClick={() => {
                                      setIsAnchorEnabled({ right: false });
                                    }}
                                  >
                                    {value.name == 'Reporting'
                                      ? 'Reports'
                                      : value.name}
                                  </Link>
                                </ListItem>
                              ) : (
                                <>
                                  {menu.map((menuData) => (
                                    <ListItem
                                      className={`${classes1.menuHeader}`}
                                      key={index}
                                    >
                                      <a
                                        className={
                                          verticalMenuVisibleValues[index]
                                            ? `${classes.racNavMenu} ${classes.racDropdownToggle} ${classes1.activeExpand}`
                                            : `${classes.racNavMenu} ${classes.racDropdownToggle}`
                                        }
                                        id={index}
                                        onClick={(e) => {
                                          verticalMenuOptionClick(e);
                                          toggleDrawer(anchor, false);
                                        }}
                                      >
                                        {menuData}
                                      </a>
                                      <List
                                        className={
                                          verticalMenuVisibleValues[index]
                                            ? `${classes1.pt0} ${classes1.menuSubHeader}`
                                            : ` ${classes1.pt0} ${classes1.menuSubHeader} ${classes1.hide}`
                                        }
                                      >
                                        {category.map((data) => {
                                          const filteredGroup = group.filter(
                                            (result: any) =>
                                              result.categories == data,
                                          );
                                          return (
                                            <>
                                              <ListItem
                                                className={`${classes1.pb0}`}
                                                key={index}
                                              >
                                                <a
                                                  className={
                                                    classes.menuSubHeader
                                                  }
                                                >
                                                  {data}
                                                </a>
                                              </ListItem>
                                              <ListItem>
                                                <List>
                                                  {filteredGroup.map(
                                                    (val, index) => (
                                                      <ListItem
                                                        key={index}
                                                        className={`${classes1.pb0}`}
                                                      >
                                                        <div
                                                          className={
                                                            classes.navMenu
                                                          }
                                                          onClick={() => {
                                                            setIsAnchorEnabled({
                                                              right: false,
                                                            });
                                                            history.push({
                                                              pathname:
                                                                val.path,
                                                            });
                                                          }}
                                                        >
                                                          {val.submenu}
                                                        </div>
                                                      </ListItem>
                                                    ),
                                                  )}
                                                </List>
                                              </ListItem>
                                            </>
                                          );
                                        })}
                                      </List>
                                    </ListItem>
                                  ))}
                                </>
                              )}
                            </>
                          );
                        })}
                      </List>
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
