import { makeStyles } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';

export const accountManagementTestId = 'accountManagementTestId';
//Added Command need to be removed
const useStyles = makeStyles(() => ({
  microAppContainer: {
    paddingTop: '3rem',
  },
}));

export const AccountManagement = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  const classes = useStyles();
  return (
    <div
      data-testid={accountManagementTestId}
      className={classes.microAppContainer}
    >
      <MicroFrontend
        config={appConfig.microfrontends.accountManagement}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
