import React, { useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  useHistory,
} from 'react-router-dom';
import { RouteName, RoutePath } from '../../../config/route-config';
import { UserContext } from '../Authenticated';
import { AllowedRoute } from './getAllowedRoutes';

interface Props extends RouteComponentProps {
  allowedRoutes: AllowedRoute[];
}

export const routesTestId = 'routesTestId';

export const Routes = withRouter((props: Props) => {
  const history = useHistory();
  // eslint-disable-next-line no-console
  console.log(`Inside Routes component`, history);
  const { activeStore }: any = useContext(UserContext);
  const { allowedRoutes } = props; //history

  const [shouldDisplayRoutes, displayRoutes] = useState<boolean>(true);

  useEffect((): void => {
    displayRoutes(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable no-console */

  useEffect((): void => {
    console.log('alloweded routes: ', allowedRoutes);
  }, [allowedRoutes]);

  return (
    <div data-testid={routesTestId}>
      {shouldDisplayRoutes && (
        <Switch>
          {/** Need to change the below logic to a better one */}
          {activeStore !== undefined ? (
            <Route exact path={RoutePath.Index}>
              <Redirect
                to={
                  allowedRoutes[0]?.name === RouteName.Payment &&
                  allowedRoutes.filter((e) => e.name == 'Payment').length == 3
                    ? '/payment1/paymentsearch'
                    : allowedRoutes[0]?.name === RouteName.Operations
                    ? '/pricing/upload'
                    : allowedRoutes[0]?.name === RouteName.Payment &&
                      allowedRoutes.filter((e) => e.name == 'Payment').length ==
                        1
                    ? '/payment'
                    : allowedRoutes[0]?.path
                }
              />
            </Route>
          ) : null}
          {allowedRoutes.map((allowedRoute: AllowedRoute) => (
            <Route
              key={allowedRoute.path}
              path={allowedRoute.path}
              component={allowedRoute.component}
            />
          ))}
        </Switch>
      )}
    </div>
  );
});
