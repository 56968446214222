/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import { makeStyles } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';

export const opsMgmtTestId = 'opsMgmtTestId';
//Added Command need to be removed
const useStyles = makeStyles(() => ({
  microAppContainer: {
    paddingTop: '3rem',
  },
}));

export const OperationsManagement = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  const classes = useStyles();
  return (
    <div data-testid={opsMgmtTestId} className={classes.microAppContainer}>
      <MicroFrontend
        config={appConfig.microfrontends.opsMgmt}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};