/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useEffect, useState, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { Header } from '../../components/Header/HeaderComponent';
import { useGlobalStateContext } from '../../context/global/global-context';
import { AllowedRoute, getAllowedRoutes } from './routes/getAllowedRoutes';
import { AllowedSubRoute, getAllowedSubRoutes } from './routes/getAllowedSubRoutes';

import { RoutesMapper } from './routes/RoutesMapper';

import { NoUser } from './routes/NoPermissions';
import { StaticContent } from '../../config/static-content-config';
import { useFullscreen } from '../../utils/useFullscreen';
import { getmenuandstore } from '../../api/user';
import { RouteName, RoutePath } from '../../config/route-config';
import { Reports } from '../../components/microfrontends/Reports';
import { NavMenuPermission } from '../../domain/Permission/NavMenuPermission';
import { getConfiguredRoutes } from './routes/getConfiguredRoutes';
import DynamicHeaderComponent from '../../components/Header/DynamicHeader/DynamicHeaderComponent';

export const authenticatedAppTestId = 'authenticatedAppTestId';
export const UserContext = createContext({});
export const ContainerContext = createContext({});

//eslint-disable-next-line sonarjs/cognitive-complexity
export const Authenticated = withRouter(() => {
  const isFullscreen = useFullscreen();
  const [storeData, setstoreData] = useState<any>();
  const [activeStore, setactiveStore]: any = useState();
  const [role, setRole] = useState<string>();
  // const [menuResponse, setMenuResponse] = useState<string>();

  const {
    currentUser: { user, error: userError },
  } = useGlobalStateContext();

  console.log('userInfo from global context', user);
  const GetRole = () => {
    return role;
  };
  const GetName = () => {
    return user?.firstName + ' ' + user?.lastName;
  };

  const GetEmployeeId = () => {
    return user?.employeeId;
  };

  const GetEmailId = () => {
    return user?.email;
  };
  const GetMenuConfig =()=>{
    return user?.menuProfile;
  }

  let allowedRoutes = undefined;
  let allowedMainRoutes = [] as AllowedRoute[];
  let allowedSubRoutes = [] as AllowedSubRoute[];
  let menuResponse:any = [];
  let allowedRouteUrls: any = [];
  /* Condition for Old Menu vs New Menu */
  if (user && user.permissions?.NAV_MENU?.length > 0) {
    const InventoryVerifyArray = user.permissions?.NAV_MENU
      ? user.permissions?.NAV_MENU.filter((el: any) => el === 'RPDINV')
      : [];
    const ManualPoVerifyArray = user.permissions?.NAV_MENU
      ? user.permissions?.NAV_MENU.filter(
        (el: any) => el === 'RPDMANPO' || el === 'RPDPORECEIVING'
      )
      : [];
    const getAllowedSubRoutesArray: any = Array.from(
      user.permissions?.NAV_MENU || []
    );
    if (InventoryVerifyArray.length == 0 && ManualPoVerifyArray.length > 0) {
      getAllowedSubRoutesArray.push('RPDINV');
    }
    // getAllowedSubRoutesArray.push('RPDADMIN');

    console.log(
      'Console getAllowedSubRoutesArray',
      getAllowedSubRoutesArray,
      user.permissions?.NAV_MENU
    );
    const isPBiReportPermissionIsEnable = getAllowedSubRoutesArray.includes('RPDPBIREPORTS')
    if (isPBiReportPermissionIsEnable) {
      getAllowedSubRoutesArray.splice(getAllowedSubRoutesArray.findIndex((el: any) => el == 'RPDREPORTS'), 1)
    }
    console.log('Nav menu', user.permissions?.NAV_MENU);
    allowedMainRoutes = getAllowedRoutes(getAllowedSubRoutesArray);
    allowedSubRoutes = getAllowedSubRoutes([...user.permissions?.NAV_MENU]);
    allowedRoutes = [...allowedMainRoutes, ...allowedSubRoutes];
    console.log('Allowded routes configured ', allowedSubRoutes);
    console.log('allowedRoutes ', allowedRoutes);
    console.log('user.menuProfile', user.menuProfile)
    const isPBiReportAvailable = user.permissions?.NAV_MENU?.includes(NavMenuPermission.ACCESS_REPORTING)
    if(isPBiReportAvailable){
      allowedRoutes.push({
        name: RouteName.Reports,
        path: RoutePath.Reports,
        component: Reports
      })
    }
  }
  else if (user && user.menuProfile && user.menuProfile?.length > 0) {
    // New Menu Routes
    const { finalRespone, allowedRoutes } = getConfiguredRoutes(user.menuProfile)
    menuResponse = finalRespone;
    allowedRouteUrls = allowedRoutes;
    // console.log(`menuResponse`, menuResponse)
  }

  useEffect(() => {
    const PageLoad = async () => {
      if (user != undefined && user.permissions?.NAV_MENU?.length > 0) {
        const empId = {
          coworkerId: user.employeeId,
          storesRequired: true,
        };
        const result = await getmenuandstore(empId);
        result.storeAssignment.sort((a: any, b: any) =>
          a.accountingUnitNumber > b.accountingUnitNumber ? 1 : -1
        );
        setactiveStore(result.storeAssignment[0].accountingUnitNumber);
        setstoreData(result.storeAssignment);
        setRole(result.coworkerProfile?.role);
      }
      else if (user && user.storeAssignment && user.storeAssignment.length > 0) {
        setactiveStore(user.storeAssignment[0].accountingUnitNumber);
        setstoreData(user.storeAssignment);
        setRole(user.coworkerProfile?.role);
      }
    };
    PageLoad();
  }, [user]);
  
  return (
    <div data-testid={authenticatedAppTestId}>
      <UserContext.Provider value={{ activeStore, setactiveStore }}>
        <ContainerContext.Provider
          value={{ GetRole, GetEmployeeId, GetName, GetEmailId, GetMenuConfig }}
        >
          <div className={clsx(!isFullscreen)}>
            {user && user.menuProfile && user.menuProfile?.length > 0 ? 
            <DynamicHeaderComponent 
                allowedRoutes={menuResponse}
                userData={user}
                dropDownData={storeData}
                allowedRouteUrls={allowedRouteUrls}
            />:
            <Header
              //key={allowedRoutes}
              passingData={allowedMainRoutes} // Used for Menu UI
              allowedSubRoutes={allowedSubRoutes} // Unwanted Usage
              dropDownData={storeData} // Used to bind stores
              userData={user ? user : {}} // Used for user info
              userRole={role ? role : ''} // Should be used
              menuNavPermissions={
                user !== undefined &&
                  user.permissions !== undefined &&
                  user.permissions.NAV_MENU !== undefined
                  ? user.permissions.NAV_MENU
                  : ''
              } // Unwanted Usage
              />}
            {
              <main className="main">
                {userError ? (
                  <NoUser
                    title={StaticContent.NoPermissionMessage}
                    text={StaticContent.ContactAdministratorMessage}
                  />
                ) : (
                  <RoutesMapper
                    allowedRoutes={allowedRoutes}
                    allowedRouteUrls={allowedRouteUrls}
                  />
                )}
              </main>
            }
          </div>
        </ContainerContext.Provider>
      </UserContext.Provider>
    </div>
  );
});
