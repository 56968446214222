/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Grid, List } from '@rentacenter/racstrap';
import { UserComponent } from '../../UserInfo/userComponent';
import { HelperComponent } from '../../Helper/helperComponent';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { headerStyles } from '../../../headerStyles';
import { GlobalSearch } from './GlobalSearch';
import RACLogo from './RACLogo';
import Menu from './Menu';
import StoreNumberWidget from './StoreNumberWidget';
import FieldDevice from './FieldDevice';
import { fieldDeviceStyles } from '../../../fieldDeviceSty';
// import { responseStructure } from './MakeResponseStructure';

// const props.allowedRoutes = responseStructure()
const searchCustomerPath = 'searchcustomer';
const DISPLAYSEARCHBAR = 'DISPLAYSEARCHBAR';

function DynamicHeaderComponent(props: any) {
  const location = useLocation();
  // eslint-disable-next-line no-console
  console.log(`props inside DynamicHeaderComponent`, props, location);
  const classes = headerStyles();
  const fieldDeviceClasses: any = fieldDeviceStyles();
  const [disPlaySearchBar, setDisPlaySearchBar] = useState('');
  const [activeAppName, setActiveAppName] = useState('');
  const path = window.location.pathname.split('/');
  const searchIconClick = (disPlaySearchBar: string) => {
    setDisPlaySearchBar(disPlaySearchBar);
  };
  let initialPath = '';
  useEffect(() => {
    let appName = '';
    if (props.allowedRoutes?.length > 0 && props.allowedRoutes[0].path) {
      appName = props.allowedRoutes[0].app;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialPath = props.allowedRoutes[0].path;
    }
    if (
      props.allowedRoutes?.length > 0 &&
      props?.allowedRoutes[0]?.appComponents?.length > 0 &&
      props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents?.length > 0
    ) {
      appName =
        props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents[0]?.appName;
      initialPath =
        props?.allowedRoutes[0]?.appComponents[0]?.appSubComponents[0]?.path;
    }
    console.log('inital path and appName', initialPath, appName);
    setActiveAppName(appName);
    // history.push({ pathname: initialPath });
  }, []);

  useEffect(() => {
    const appNameInPath = location.pathname?.split('/')[1];
    const appDetail = props.allowedRouteUrls.find(
      (el: any) => el.routePath.replaceAll('/', '') == appNameInPath
    );
    console.log('dynamic header app name', appDetail, appNameInPath);
    setActiveAppName(appDetail?.appName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="App">
      <Grid
        classes={{ root: clsx(classes.racNavbarExpand, classes.racFixedTop) }}
      >
        <Grid className={classes.racContainerFluid}>
          <RACLogo initialPath={initialPath} />
          <Grid
            classes={{
              root: clsx(classes.racNavbarCollapse, classes.ContentEnd),
            }}
          >
            <List
              className={`${classes.racNavbarNav} ${fieldDeviceClasses.dMdNone}`}
            >
              {props.allowedRoutes.map((userNavElements: any, index: any) => {
                return (
                  <Menu
                    key={index}
                    index={index}
                    userNavElements={userNavElements}
                    activeAppName={activeAppName}
                  />
                );
              })}
            </List>
            <Grid className={classes.dFlex}>
              {path[3] !== searchCustomerPath ? (
                <Link
                  to="#"
                  className={clsx({
                    [classes.racGlobalSearchBtnShow]:
                      disPlaySearchBar == DISPLAYSEARCHBAR,
                    [classes.racGlobalSearchBtn]: disPlaySearchBar == '',
                    [classes.cursorDefault]:
                      path[2] == 'customer' && path[3] == searchCustomerPath,
                  })}
                  onClick={() => searchIconClick(DISPLAYSEARCHBAR)}
                ></Link>
              ) : null}
              <GlobalSearch
                disPlaySearchBar={disPlaySearchBar}
                searchOutsideClick={(val: string) => searchIconClick(val)}
              />
              <StoreNumberWidget
                dropdown={props.dropDownData}
                initialPath={initialPath}
              />
              <HelperComponent throwDropdownValue={props.dropDownData} />
              <UserComponent userData={props?.userData} />
              <FieldDevice navPermission={props.allowedRoutes} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DynamicHeaderComponent;
