/* eslint-disable no-console */
import React from 'react';
//import { Spinner } from 'reactstrap';

import { Routes } from './Routes';
// import { AllowedRoute } from './getAllowedRoutes';
import { NoPermissions } from './NoPermissions';
import { StaticContent } from '../../../config/static-content-config';
import { NewRoutes } from './NewRoutes';
// import { RouteComponentProps } from 'react-router';

export const spinnerTestId = 'spinnerTestId';

// interface Props extends RouteComponentProps {
//   allowedRoutes?: AllowedRoute[];
// }

export const RoutesMapper = ({ allowedRoutes, allowedRouteUrls }: any) => {
  console.log(`Inside RoutesMapper component`);
  if (!allowedRoutes && allowedRouteUrls?.length == 0) {
    return <div></div>;
  }

  if (allowedRoutes && allowedRoutes.length > 0) {
    return <Routes key={allowedRoutes} allowedRoutes={allowedRoutes} />;
  } else if (allowedRouteUrls?.length > 0) {
    return <NewRoutes allowedRouteUrls={allowedRouteUrls} />;
  }

  return (
    <NoPermissions
      title={StaticContent.NoProductMessage}
      text={StaticContent.ContactSupportMessage}
    />
  );
};
