import React, { useMemo } from 'react';

import { useAuthContext } from '../../context/auth/auth-context';
// import { Routes } from '../../app/Routes';
import { Unauthenticated } from '../Unauthenticated/Unauthenticated';
import { Authenticated } from '../../app/authenticated/Authenticated';
import { UserFetcher } from '../../app/UserFetcher';
import { GlobalContextProvider } from '../../context/global/GlobalContextProvider';

export const tryingToFetchTheUserTestId = 'tryingToFetchTheUserTestId';
export const noUserTestId = 'noUserTestId';

// export const AuthGuard = () => {
//   const { userTokenData, triedToFetchTheUser } = useAuthContext();
//   const { isRenderedByContainer } = window;

//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   const fallback = isRenderedByContainer ? (
//     <div data-testid={noUserTestId} />
//   ) : (
//     <Unauthenticated />
//   );

//   return useMemo(() => {
//     if (triedToFetchTheUser) {
//       return <>{userTokenData ? <Routes /> : fallback}</>;
//     }

//     return <div data-testid={tryingToFetchTheUserTestId} />;
//   }, [userTokenData, triedToFetchTheUser, fallback]);
// };
export const AuthGuard = () => {
  const { userTokenData } = useAuthContext();

  return useMemo(() => {
    if (!userTokenData) {
      return <Unauthenticated />;
    }

    return (
      <>
        <GlobalContextProvider>
          <UserFetcher />
          <Authenticated />
        </GlobalContextProvider>
      </>
    );
  }, [userTokenData]);
};
