/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { FC } from "react";
import { RouteComponentProps } from "react-router-dom";

import { NavMenuPermission } from "../../../domain/Permission/NavMenuPermission";
import {
  canAccessDash,
  canAccessPay,
  // canAccessCustomer,
  // canAccessAgreement,
  canAccessAccountManagement,
  canAccessInventory,
  canAccessReports,
  canAccessStoreMagement,
  canAccessConfigurations,
  canAccessCustomer,
  canAccessPricing,
  canAccessReporting,
  // canAccessReporting,
  // canAccessAdmin,
} from "../../../domain/Permission/permissions";
import { Dashboard } from "../../../components/microfrontends/Dashboard";
import { Payment } from "../../../components/microfrontends/Payment";
// import { Customer } from '../../../components/microfrontends/Customer';
import { AccountManagement } from "../../../components/microfrontends/AccountManagement";
import { Inventory } from "../../../components/microfrontends/Inventory";
import { Reports } from "../../../components/microfrontends/Reports";
// import { Agreement } from '../../../components/microfrontends/Agreement';
import { RouteName, RoutePath } from "../../../config/route-config";
import { Configurations } from "../../../components/microfrontends/Configurations";
import { Customer } from "../../../components/microfrontends/Customer";
import { DOF } from "../../../components/microfrontends/DOF";
// import { Admin } from '../../../components/microfrontends/Admin';
import { Pricing } from "../../../components/microfrontends/Pricing";
import { Reporting } from "../../../components/microfrontends/Reporting";
// import { Admin } from '../../../components/microfrontends/Admin';
// import { Reporting } from "../../../components/microfrontends/Reporting";

export interface AllowedRoute {
  readonly name: RouteName;
  readonly path: string;
  readonly component: FC<RouteComponentProps>;
  readonly displayOrder: number;
}

export const getAllowedRoutes = (
  actions: NavMenuPermission[]
): AllowedRoute[] =>
  actions
    .reduce((configs: any, action: NavMenuPermission) => {
      console.log("configactions", actions);
      if (canAccessDash(action)) {
        console.log("configactions", actions);
        return [
          ...configs,
          {
            name: RouteName.Index,
            path: RoutePath.Dashboard,
            component: Dashboard,
            displayOrder: 1,
          },
        ];
      }

      if (canAccessDash(action)) {
        return [
          ...configs,
          {
            name: RouteName.Dashboard,
            path: RoutePath.Dashboard,
            component: Dashboard,
            displayOrder: 2,
          },
        ];
      }

      if (canAccessPay(action)) {
        return [
          ...configs,
          {
            name: RouteName.Payment,
            path: RoutePath.Payment,
            component: Payment,
            displayOrder: 3,
          },
        ];
      }

      if (canAccessCustomer(action)) {
        return [
          ...configs,
          {
            name: RouteName.Customer,
            path: RoutePath.Customer,
            component: Customer,
            displayOrder: 4,
          },
        ];
      }

      if (canAccessAccountManagement(action)) {
        return [
          ...configs,
          {
            name: RouteName.AccountManagement,
            path: RoutePath.AccountManagement,
            component: AccountManagement,
            displayOrder: 6,
          },
        ];
      }

      if (canAccessInventory(action)) {
        return [
          ...configs,
          {
            name: RouteName.Inventory,
            path: RoutePath.Inventory,
            component: Inventory,
            displayOrder: 7,
          },
        ];
      }
      if (canAccessReporting(action)) {
        return [
          ...configs,
          {
            name: RouteName.Reporting,
            path: RoutePath.Reporting,
            component: Reporting,
            displayOrder: 8,
          },
        ];
      }else if (canAccessReports(action)) {
        return [
          ...configs,
          {
            name: RouteName.Reports,
            path: RoutePath.Reports,
            component: Reports,
            displayOrder: 8,
          },
        ];
      }
      
      

      if (canAccessStoreMagement(action)) {
        return [
          ...configs,
          {
            name: RouteName.Store,
            path: RoutePath.Store,
            component: DOF,
            displayOrder: 9,
          },
        ];
      }

      if (canAccessPricing(action)) {
        return [
          ...configs,
          {
            name: RouteName.Operations,
            path: RoutePath.Pricing,
            component: Pricing,
            displayOrder: 11,
          },
        ];
      }

      if (canAccessConfigurations(action)) {
        return [
          ...configs,
          {
            name: RouteName.Configurations,
            path: RoutePath.Configurations,
            component: Configurations,
            displayOrder: 10,
          },
        ];
      }
      // if (canAccessAdmin(action)) {
      //   return [
      //     ...configs,
      //     {
      //       name: RouteName.Admin,
      //       path: RoutePath.Store,
      //       component: Admin,
      //       displayOrder: 11,
      //     },
      //   ];
      // }

      return configs;
    }, [])
    .sort((a: AllowedRoute, b: AllowedRoute) =>
      a.displayOrder > b.displayOrder ? 1 : -1
    );
