/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  RACButton,
  SwipeableDrawer,
  Box,
} from '@rentacenter/racstrap';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Hamburger } from '../../../assets/images/hamburger-menu.svg';
import { ReactComponent as CanvasClose } from '../../../assets/images/canvasCloseIcon.svg';
import { fieldDeviceStyles } from '../../../fieldDeviceSty';
import { headerStyles } from '../../../headerStyles';
type Anchor = 'right';
function FieldDevice(props: any) {
  const history = useHistory();
  const classes = headerStyles();
  const classes1 = fieldDeviceStyles();

  const [isAnchorEnabled, setIsAnchorEnabled] = useState({ right: false });
  const [verticalMenuVisibleValues, setVerticalMenuVisibleValues]: any =
    useState([]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setIsAnchorEnabled({ ...isAnchorEnabled, [anchor]: open });
    };
  const verticalMenuOptionClick = (e: any) => {
    const indexOfMenu = e.target.id;
    const menuVisibleArray = new Array(verticalMenuVisibleValues.length);
    menuVisibleArray.fill(false);
    if (!verticalMenuVisibleValues[indexOfMenu]) {
      menuVisibleArray[indexOfMenu] = true;
    }
    setVerticalMenuVisibleValues(menuVisibleArray);
  };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Box>
  );

  return (
    <Grid className={`${classes1.dMdBlock} ${classes1.hide}`}>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <RACButton
            endIcon={<Hamburger />}
            onClick={toggleDrawer(anchor, true)}
          ></RACButton>
          <SwipeableDrawer
            anchor={anchor}
            open={isAnchorEnabled[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <Grid
              className={`${classes1.justifyContentEnd} ${classes1.fixedTop}`}
            >
              <RACButton
                onClick={toggleDrawer(anchor, false)}
                endIcon={<CanvasClose />}
              ></RACButton>
            </Grid>
            {/* dynamic binding starts here */}
            <List>
              {/* <ListItem className={`${classes1.mt2}`}>
                                <Link
                                    to={'/dashboard/homepage'}
                                    className={`${classes1.racNavMenu}`}
                                    onClick={() => {
                                        setIsAnchorEnabled({ right: false });
                                    }}
                                >
                                    Dashboard
                                </Link>
                            </ListItem> */}
              {props.navPermission.map((value: any, index: any) => {
                // eslint-disable-next-line no-debugger
                // debugger
                // const group = value.appComponents
                // const menuOptions = groupBy(group, 'menu');
                const menu = [value.app];
                const group = value.appComponents
                  .map((el: any) => el.appSubComponents)
                  .flat(Infinity);
                const category = Array.from(
                  new Set(group.map((el: any) => el.appComponentName))
                );

                // const group = value.appComponents
                // // const menuOptions = value?.appComponents
                // // const menu = Object.keys(menuOptions);
                // const subgroup = value?.appComponents
                // const category = Object.keys(subgroup);
                return (
                  <>
                    {value.appComponents.length == 0 ? (
                      <ListItem className={`${classes1.mt2}`}>
                        <Link
                          to={value.path}
                          className={`${classes.racNavMenu}`}
                          onClick={() => {
                            setIsAnchorEnabled({ right: false });
                          }}
                        >
                          {value.app}
                        </Link>
                      </ListItem>
                    ) : (
                      <>
                        {menu.map((menuData: any) => (
                          <ListItem
                            className={`${classes1.menuHeader}`}
                            key={index}
                          >
                            <a
                              className={
                                verticalMenuVisibleValues[index]
                                  ? `${classes.racNavMenu} ${classes.racDropdownToggle} ${classes1.activeExpand}`
                                  : `${classes.racNavMenu} ${classes.racDropdownToggle}`
                              }
                              id={index}
                              onClick={(e) => {
                                verticalMenuOptionClick(e);
                                toggleDrawer(anchor, false);
                              }}
                            >
                              {menuData}
                            </a>
                            <List
                              className={
                                verticalMenuVisibleValues[index]
                                  ? `${classes1.pt0} ${classes1.menuSubHeader}`
                                  : ` ${classes1.pt0} ${classes1.menuSubHeader} ${classes1.hide}`
                              }
                            >
                              {category.map((data: any) => {
                                const filteredGroup = group.filter(
                                  (result: any) =>
                                    result.appComponentName == data
                                );
                                return (
                                  <>
                                    <ListItem
                                      className={`${classes1.pb0}`}
                                      key={index}
                                    >
                                      <a className={classes1.menuSubHeader}>
                                        {data}
                                      </a>
                                    </ListItem>
                                    <ListItem>
                                      <List>
                                        {filteredGroup.map(
                                          (val: any, index: any) => (
                                            <ListItem
                                              key={index}
                                              className={`${classes1.pb0}`}
                                            >
                                              <div
                                                className={classes.navMenu}
                                                onClick={() => {
                                                  setIsAnchorEnabled({
                                                    right: false,
                                                  });
                                                  history.push({
                                                    pathname: val.path,
                                                  });
                                                }}
                                              >
                                                {val.appSubComponenet}
                                              </div>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </ListItem>
                                  </>
                                );
                              })}
                            </List>
                          </ListItem>
                        ))}
                      </>
                    )}
                  </>
                );
              })}
            </List>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </Grid>
  );
}

export default FieldDevice;
