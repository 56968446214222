import React, { useState, useContext } from 'react';
import { RACSearchSelect, Grid, Typography } from '@rentacenter/racstrap';
import { headerStyles } from '../../../headerStyles';
import OutsideClickHandler from 'react-outside-click-handler';
import { UserContext } from '../../../app/authenticated/Authenticated';
import { useGlobalDispatchContext } from '../../../context/global/global-context';
import { useHistory } from 'react-router-dom';
import { GetStoreChangeAction } from '../../../context/global/store/store-action';

function StoreNumberWidget(props: any) {
  const history = useHistory();
  const { activeStore }: any = useContext(UserContext);
  const dispatch: any = useGlobalDispatchContext();

  const [activeStoreNumber, setActiveStoreNumber] = useState(activeStore);
  const classes = headerStyles();
  const storeDisplay = (e: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    const storeNum = e.target.innerText.split(' ');
    const storeIndex = props.dropdown.findIndex(
      (e: any) => e.accountingUnitNumber == storeNum[2]
    );
    setActiveStoreNumber(storeNum[2]);
    sessionStorage.setItem('storeNumber', storeNum[2]);
    sessionStorage.setItem(
      'storeDetails',
      Buffer.from(
        JSON.stringify(props.dropdown[storeIndex].storeDetails)
      ).toString('base64')
    );
    dispatch(GetStoreChangeAction(storeNum[2]));
    history.push({
      pathname: props.initialPath,
    });
  };
  const dropdownStrap = (dropdown: any) => {
    if (!dropdown || dropdown.length == 0) return [];
    return dropdown.map((obj: any) => {
      return {
        label: 'Store - ' + obj.accountingUnitNumber,
        value: obj.accountingUnitNumber,
      };
    });
  };

  return props.dropdown ? (
    <Grid
      className={`${classes.racDropdown} ${
        props.dropdown.length !== 1 ? classes.dropWidth : null
      }`}
    >
      <OutsideClickHandler onOutsideClick={() => null}>
        {props.dropdown.length !== 1 ? (
          <RACSearchSelect
            options={dropdownStrap(props.dropdown)}
            backgroundColorCondition={true}
            backgroundColor="#f2f2ff"
            inputRootClass={classes.dropheight}
            value={
              window.sessionStorage.getItem('storeNumber')
                ? `Store - ${window.sessionStorage.getItem('storeNumber')}`
                : activeStoreNumber != ''
                ? `Store - ${activeStoreNumber}`
                : ''
            }
            onChange={(e) => storeDisplay(e)}
            rootClass={classes.DropBorder}
            inputClass={classes.textFont}
            IconClassName={classes.IconClass}
            optionClass={classes.dropdownitemstyle}
            onKeyDown={(event) => {
              event.defaultMuiPrevented = true;
            }}
          ></RACSearchSelect>
        ) : (
          <Typography className={classes.singleStore}>
            Store - {props.dropdown[0].accountingUnitNumber}
          </Typography>
        )}
      </OutsideClickHandler>
    </Grid>
  ) : (
    <Grid className={classes.racDropdown}>
      <Typography className={classes.singleStore}>No Stores</Typography>
    </Grid>
  );
}

export default StoreNumberWidget;
