import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { RoutePath } from '../../../config/route-config';
import { UserContext } from '../Authenticated';
import { Reports } from '../../../components/microfrontends/Reports';
// import { AllowedRoute } from './getAllowedRoutes';

interface Props extends RouteComponentProps {
  allowedRouteUrls: any;
}

export const routesTestId = 'routesTestId';

export const NewRoutes = withRouter((props: Props) => {
  const reportPath = `/reports`;
  const reportingPath = '/reporting';
  const { activeStore }: any = useContext(UserContext);
  const { allowedRouteUrls } = props; //history

  // const componentRoutes: any = [];
  // eslint-disable-next-line no-console
  console.log(`inside New routes`, allowedRouteUrls, activeStore);
  // menuResponse.forEach((el: any) => {
  //   if (el.appComponents?.length == 0) {
  //     componentRoutes.push({ path: el.path, component: el.component });
  //   } else {
  //     el.appComponents.forEach((appComponent: any) => {
  //       appComponent.appSubComponents.forEach((subComponent: any) => {
  //         componentRoutes.push({
  //           path: subComponent.path,
  //           component: subComponent.component,
  //         });
  //       });
  //     });
  //   }
  // });

  return (
    <div data-testid={routesTestId}>
      <Switch>
        {activeStore !== undefined ? (
          <Route exact path={RoutePath.Index}>
            <Redirect
              to={(allowedRouteUrls && allowedRouteUrls[0]?.path) || '#'}
            />
          </Route>
        ) : null}
        {allowedRouteUrls.map((componentRoute: any) => (
          <Route
            key={componentRoute.routePath}
            path={componentRoute.routePath}
            component={componentRoute.component}
          />
        ))}
        {allowedRouteUrls.some((el: any) => el.routePath == reportingPath) ? (
          <Route key={reportPath} path={reportPath} component={Reports} />
        ) : null}
      </Switch>
    </div>
  );
});
