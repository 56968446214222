/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, MenuList, MenuItem } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { configCall } from '../../api/user';
import { headerStyles } from '../../headerStyles';
import Helper from '../../assets/images/filled-help.png';
import JobAid from '../../assets/images/jobaid.png';
import OutsideClickHandler from 'react-outside-click-handler';
import Phone from '../../assets/images/phonegrey.png';
import NewFeature from '../../assets/images/newfeature.png';
import Settings from '../../assets/images/settings.png';
import { ReactComponent as ReportIssue } from '../../assets/images/Reports Icon.svg';

export const HelperComponent = (props: any) => {
  const classes = headerStyles();
  const [hideHelper, setHideHelper] = useState('');
  const [helperData, setHelperData]: any = useState({
    whatsNewURL: '',
    jobAidURL: '',
    serviceNowURL: '',
    phoneNumber: '-',
    reportURL: '',
  });

  useEffect(() => {
    const PageLoad = async () => {
      const configPayload = {
        storeNumbers: [props.throwDropdownValue[0].accountingUnitNumber],
        paramKeyNames: [
          'ReleaseNotesURL',
          'ServiceNowURL',
          'JobAidURL',
          'SupportContactNumber',
          'ReportIssue',
        ],
      };
      const configResult = await configCall(configPayload);
      if (configResult) {
        const filterReleaseNotesUrl =
          configResult.storeProfileResponse.configDetails[0].configDetails.filter(
            (el: any) => el.paramKeyName == 'ReleaseNotesURL'
          );
        const filterSNUrl =
          configResult.storeProfileResponse.configDetails[0].configDetails.filter(
            (el: any) => el.paramKeyName == 'ServiceNowURL'
          );
        const filterJobAidUrl =
          configResult.storeProfileResponse.configDetails[0].configDetails.filter(
            (el: any) => el.paramKeyName == 'JobAidURL'
          );
        const filterStorePHNumber =
          configResult.storeProfileResponse.configDetails[0].configDetails.filter(
            (el: any) => el.paramKeyName == 'SupportContactNumber'
          );
        const filterReportUrl =
          configResult.storeProfileResponse.configDetails[0].configDetails.filter(
            (el: any) => el.paramKeyName == 'ReportIssue'
          );
        setHelperData({
          whatsNewURL: filterReleaseNotesUrl[0].paramValue,
          jobAidURL: filterJobAidUrl[0].paramValue,
          serviceNowURL: filterSNUrl[0].paramValue,
          phoneNumber: filterStorePHNumber[0].paramValue,
          reportURL: filterReportUrl[0].paramValue,
        });
      }
    };
    if (props.throwDropdownValue && props.throwDropdownValue.length > 0) {
      PageLoad();
    }
  }, [props.throwDropdownValue]);
  const helperClicked = () => {
    hideHelper == '' ? setHideHelper('show') : setHideHelper('');
  };
  const redirectionClicked = (invoker: any) => {
    if (invoker == 'New Feature' && helperData.whatsNewURL !== '') {
      window.open(helperData.whatsNewURL, '_blank');
    } else if (invoker == 'Job Aids' && helperData.jobAidURL !== '') {
      window.open(helperData.jobAidURL, '_blank');
    } else if (invoker == 'SN Portal' && helperData.serviceNowURL !== '') {
      window.open(helperData.serviceNowURL, '_blank');
    } else if (invoker == 'Report Issue' && helperData.reportURL !== '') {
      window.open(helperData.reportURL, '_blank');
    }
  };
  return (
    <Grid
      className={classes.racDropdown}
      data-testid="profilebtn"
      onClick={helperClicked}
    >
      <a
        data-testid="logoutbtn"
        className={hideHelper === 'show' ? 'racUser show' : 'racUser'}
        id="profilemenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          className={classes.helperIconStyle}
          src={Helper}
          alt="search-icon"
        />
      </a>
      <OutsideClickHandler onOutsideClick={() => setHideHelper('')}>
        <MenuList
          data-testid="logouttest"
          className={clsx({
            [classes.racGlobalSearchShow]: hideHelper === 'show',
          })}
          classes={{
            root: clsx(
              classes.racProfileContent,
              classes.racProfileMenu,
              classes.helperList
            ),
          }}
          aria-labelledby="profilemenu"
        >
          <MenuItem
            className={`${classes.menuItem} ${classes.cursorDefault}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={3}>
                <Typography className={classes.helpText}>Help</Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={2}>
                <img
                  className={classes.racStandardInfoIcon}
                  src={NewFeature}
                  alt="new-icon"
                  onClick={() => redirectionClicked('New Feature')}
                />
              </Grid>
              <Grid item md={9}>
                <Typography onClick={() => redirectionClicked('New Feature')}>
                  What's New
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={2}>
                <img
                  className={classes.racStandardInfoIcon}
                  src={JobAid}
                  alt="job-icon"
                  onClick={() => redirectionClicked('Job Aids')}
                />
              </Grid>
              <Grid item md={9}>
                <Typography onClick={() => redirectionClicked('Job Aids')}>
                  Job Aids
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <Grid container>
              <Grid item md={2}>
                <ReportIssue
                  className={classes.racStandardInfoIcon}
                  onClick={() => redirectionClicked('Report Issue')}
                />
              </Grid>
              <Grid item md={9}>
                <Typography onClick={() => redirectionClicked('Report Issue')}>
                  Report an Issue
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={2}>
                <img
                  className={classes.racStandardInfoIcon}
                  src={Settings}
                  alt="settings-icon"
                  onClick={() => redirectionClicked('SN Portal')}
                />
              </Grid>
              <Grid item md={9}>
                <Typography onClick={() => redirectionClicked('SN Portal')}>
                  ServiceNow Portal
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem} ${classes.cursorDefault}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={2}>
                <img
                  className={classes.racStandardInfoIcon}
                  src={Phone}
                  alt="phone-icon"
                />
              </Grid>
              <Grid item md={9}>
                <Typography>{helperData.phoneNumber}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </MenuList>
      </OutsideClickHandler>
    </Grid>
  );
};
