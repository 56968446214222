import React, { useState } from 'react';
import {
  Grid,
  Typography,
  MenuList,
  MenuItem,
  RACButton,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { headerStyles } from '../../headerStyles';
import userImage from '../../assets/images/standard icon.png';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as CloseIcon } from '../../assets/images/close_icon_2.svg';
import { useAuthContext } from '../../context/auth/auth-context';

export const UserComponent = (props: any) => {
  const classes = headerStyles();
  const { logout } = useAuthContext();

  const [hideShowProfile, showProfile] = useState('');

  const Profile = () => {
    hideShowProfile == '' ? showProfile('show') : showProfile('');
  };
  return (
    <Grid
      className={classes.racDropdown}
      data-testid="profilebtn"
      onClick={Profile}
    >
      <a
        data-testid="logoutbtn"
        className={hideShowProfile === 'show' ? 'racUser show' : 'racUser'}
        id="profilemenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          className={classes.racStandardIcon}
          src={userImage}
          alt="search-icon"
        />
      </a>
      <OutsideClickHandler onOutsideClick={() => showProfile('')}>
        <MenuList
          data-testid="logouttest"
          className={clsx({
            [classes.racGlobalSearchShow]: hideShowProfile === 'show',
          })}
          classes={{
            root: clsx(
              classes.racProfileContent,
              classes.racProfileMenu,
              classes.menuList
            ),
          }}
          aria-labelledby="profilemenu"
        >
          <MenuItem
            className={`${classes.menuItem} ${classes.cursorDefault}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={3}>
                <Typography>First Name:</Typography>
              </Grid>
              <Grid item md={8}>
                <Typography>
                  {props != undefined &&
                  props.userData != undefined &&
                  props.userData != {} &&
                  props.userData.firstName != undefined
                    ? props.userData.firstName
                    : ''}
                </Typography>
              </Grid>
              <Grid item md={1}>
                <CloseIcon className={classes.closeIcon} />
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem} ${classes.cursorDefault}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={3}>
                <Typography>Last Name:</Typography>
              </Grid>
              <Grid item md={9}>
                <Typography>
                  {props != undefined &&
                  props.userData != undefined &&
                  props.userData != {} &&
                  props.userData.lastName != undefined
                    ? props.userData.lastName
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          <MenuItem
            className={`${classes.menuItem} ${classes.cursorDefault}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={3}>
                <Typography>Email ID:</Typography>
              </Grid>
              <Grid item md={9}>
                <Typography>
                  {props != undefined &&
                  props.userData != undefined &&
                  props.userData != {} &&
                  props.userData.email != undefined
                    ? props.userData.email
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
          {props != undefined &&
          props.userRole != undefined &&
          props.userRole != '' ? (
            <MenuItem
              className={`${classes.menuItem} ${classes.cursorDefault}`}
              disableRipple
              disableTouchRipple
            >
              <Grid container>
                <Grid item md={3}>
                  <Typography>Role:</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography>
                    {props != undefined && props.userRole != undefined
                      ? props.userRole
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ) : null}
          <MenuItem
            className={`${classes.menuItem} ${classes.btnAlign}`}
            disableRipple
            disableTouchRipple
          >
            <Grid container>
              <Grid item md={12}>
                <RACButton color="primary" variant="contained" onClick={logout}>
                  Logout
                </RACButton>
              </Grid>
            </Grid>
          </MenuItem>
        </MenuList>
      </OutsideClickHandler>
    </Grid>
  );
};
