/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from '@rentacenter/racstrap';
// import searchImageActive from '../../assets/images/search-active-icon.svg';
import searchImageActive from './assets/images/search-active-icon.svg';
import searchImageDefault from './assets/images/Search-icon.svg';
import GlobalSearchBtn from './assets/images/search-btn.svg';
export const headerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    racLogoResponsive: {
      [theme.breakpoints.down(1281)]: {
        width: '100%',
      },
    },
    racNavBarBrand: {
      [theme.breakpoints.down(1281)]: {
        width: '100px',
      },
    },
    racFixedTop: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 1030,
      alignItems: 'center',
      display: 'flex',
      boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%)',
      background: `${RACCOLOR.WHITE}`,
    },
    racContainerFluid: {
      width: '100%',
      paddingRight: theme.typography.pxToRem(12),
      paddingLeft: theme.typography.pxToRem(12),
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      flexWrap: 'inherit',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    racNavbarExpand: {
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
    },
    racNavbarCollapse: {
      display: 'flex',
      flexBasis: 'auto',
      flexGrow: 1,
      alignItems: 'center',
    },
    racNavbarNav: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: theme.typography.pxToRem(0),
      paddingBottom: theme.typography.pxToRem(0),
      paddingTop: theme.typography.pxToRem(0),
      marginBottom: theme.typography.pxToRem(0),
      marginLeft: theme.typography.pxToRem(15),
      marginRight: 'auto',
      listStyle: 'none',
      position: 'inherit',
    },
    racDropdownMenu: {
      position: 'absolute',
    },

    racNavBar: {
      position: 'inherit',
      display: 'inline-flex',
      paddingTop: theme.typography.pxToRem(5),
      paddingBottom: theme.typography.pxToRem(5),
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
      marginLeft: theme.typography.pxToRem(4),
      [theme.breakpoints.down(1281)]: {
        marginLeft: theme.typography.pxToRem(0),
      },
      '&:hover': {
        '& $racNavMenu': {
          background: '#438fff',
          color: `${RACCOLOR.WHITE}`,
          borderRadius: '3px',
        },
        '& $racMenuContent': {
          display: 'block',
          zIndex: 9999,
        },
        '& $racDropdownMenuTransition': {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          webkitTransition: 'max-height 0.7s, opacity 0.7s, visibility 0s',
          mozTransition: 'max-height 0.7s, opacity 0.7s, visibility 0s',
          msTransition: 'max-height 0.7s, opacity 0.7s, visibility 0s',
          oTransition: 'max-height 0.7s, opacity 0.7s, visibility 0s',
          transition: 'max-height 0.7s, opacity 0.7s, visibility 0s',
          maxHeight: '120px',
          opacity: '1',
          visibility: 'visible',
        },
      },
    },
    racNavBarNone: {
      position: "inherit",
      display: "inline-flex",
      paddingTop: theme.typography.pxToRem(5),
      paddingBottom: theme.typography.pxToRem(5),
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
      marginLeft: theme.typography.pxToRem(4),
      [theme.breakpoints.down(1281)]: {
        marginLeft: theme.typography.pxToRem(0),
      },
      "&:hover": {
        "& $racNavMenu": {
          background: "#438fff",
          color: `${RACCOLOR.WHITE}`,
          borderRadius: "3px",
        },
        "& $racMenuContent": {
          display: "none",
          zIndex: 9999,
        },
        "& $racDropdownMenuTransition": {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          webkitTransition: "max-height 0.7s, opacity 0.7s, visibility 0s",
          mozTransition: "max-height 0.7s, opacity 0.7s, visibility 0s",
          msTransition: "max-height 0.7s, opacity 0.7s, visibility 0s",
          oTransition: "max-height 0.7s, opacity 0.7s, visibility 0s",
          transition: "max-height 0.7s, opacity 0.7s, visibility 0s",
          maxHeight: "120px",
          opacity: "1",
          visibility: "visible",
        },
      },
    },
    racNavMenu: {
      color: `${RACCOLOR.WASHED_BLACK}`,
      fontSize: theme.typography.pxToRem(15),
      [theme.breakpoints.down(1281)]: {
        fontSize: theme.typography.pxToRem(12),
      },
      padding: theme.typography.pxToRem(10),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-bold',
      textDecoration: 'none',
      '&:hover': {
        background: '#438fff',
        color: `${RACCOLOR.WHITE}`,
        borderRadius: '5px',
      },
    },
    ContentEnd: {
      justifyContent: 'flex-end',
    },
    racDropdownToggle: {
      color: `${RACCOLOR.WASHED_BLACK}`,
      fontSize: theme.typography.pxToRem(15),
      [theme.breakpoints.down(1281)]: {
        fontSize: theme.typography.pxToRem(12),
      },
      padding: theme.typography.pxToRem(10),
      fontFamily: 'OpenSans-bold',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      '&:hover': {
        background: '#438fff',
        color: `${RACCOLOR.WHITE}`,
        borderRadius: '5px',
        '&:after': {
          borderColor: `${RACCOLOR.WHITE}`,
        },
      },
      '&:after': {
        border: 'solid #606D89',
        borderWidth: '0 1.8px 1.8px 0',
        padding: '3px',
        transform: 'rotate(45deg)',
        display: 'inline-block',
        content: '""',
        verticalAlign: '2px',
        marginLeft: '7px',
        position: 'relative',
      },
    },
    racMenuContent: {
      width: '100%',
      transform: 'none',
      top: '100%',
      left: 0,
      border: 'none',
      boxShadow: '0px 2px 3px 0px #e0e0e0',
      borderRadius: '0px 0px 20px 20px',
      position: 'absolute',
      zIndex: 1000,
      display: 'none',
      padding: '1rem 0',
      margin: 0,
      color: '#212529',
      textAlign: 'left',
      background: `${RACCOLOR.WHITE}`,
    },
    racProfileContent: {
      transform: 'none',
      top: '100%',
      left: 0,
      border: 'none',
      boxShadow: '0px 2px 3px 0px #e0e0e0',
      position: 'absolute',
      zIndex: 1000,
      display: 'none',
      padding: '0.5rem 0',
      margin: 0,
      color: '#212529',
      textAlign: 'left',
      background: `${RACCOLOR.WHITE}`,
    },
    racMegaMenu: {
      margin: '0 183px',
      width: '80%',
    },
    menuSubHeader: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(17),
      [theme.breakpoints.down(1281)]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    navMenu: {
      textDecoration: 'none',
      color: `${RACCOLOR.WASHED_BLACK}`,
      paddingBottom: theme.typography.pxToRem(8),
      paddingTop: theme.typography.pxToRem(8),
      display: 'block',
      paddingRight: theme.typography.pxToRem(64),
      [theme.breakpoints.down(1281)]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    dFlex: {
      display: 'flex',
    },
    formLabel: {
      fontFamily: 'OpenSans-semibold',
      color: `${RACCOLOR.WASHED_BLACK}`,
      fontSize: theme.typography.pxToRem(14),
    },
    formInputAlign: {
      marginTop: '0.3rem',
    },
    formInputAlignDate: {
      marginTop: '0.3rem',
      // '& div': {
      //   '& div': {
      //     '& fieldset': {
      //       borderColor: '#c4c4c4 !important',
      //     },
      //     '& input': {
      //       padding: '4px',
      //       paddingLeft: '12px',
      //       '&:focus': {
      //         boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%) !important',
      //         borderColor: '#80bdff !important',
      //         borderRadius: '0.3125rem !important',
      //       },
      //     },
      //   },
      // },
    },
    PT0PB0: {
      paddingBottom: '0 !important',
      paddingTop: '0 !important',
    },
    racColMD12: {
      flex: '0 0 auto',
      width: '100%',
    },
    racColMD1: {
      flex: '0 0 auto',
      width: '8.33%',
    },
    racColMD2: {
      flex: '0 0 auto',
      width: '16.66%',
    },
    racColMD3: {
      flex: '0 0 auto',
      width: '25%',
    },
    racColMD4: {
      flex: '0 0 auto',
      width: '33.33%',
    },
    racColMD5: {
      flex: '0 0 auto',
      width: '36.66%',
    },
    racColMD6: {
      flex: '0 0 auto',
      width: '43%',
    },
    racColMD7: {
      flex: '0 0 auto',
      width: '65%',
    },
    racColMD8: {
      flex: '0 0 auto',
      width: '66.66%',
    },
    racColMD9: {
      flex: '0 0 auto',
      width: '75%',
    },
    racColMD10: {
      flex: '0 0 auto',
      width: '83.33%',
    },
    racColMD11: {
      flex: '0 0 auto',
      width: '91.66%',
    },
    paddingTB: {
      padding: '10px 0px',
    },
    racGlobalSearchBtn: {
      backgroundImage: `url(${searchImageDefault})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: '100%',
      cursor: 'pointer',
      width: '46px',
      float: 'left',
      paddingTop: '0.25rem',
    },

    racGlobalSearchBtnShow: {
      backgroundImage: `url(${searchImageActive})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: '100%',
      cursor: 'pointer',
      width: '46px',
      float: 'left',
      paddingTop: '0.25rem',
    },
    racGlobalSearchShow: {
      display: 'block',
    },
    racDropdown: {
      position: 'relative',
      float: 'left',
      marginLeft: theme.typography.pxToRem(8),
      marginRight: theme.typography.pxToRem(8),
    },
    racDropdownItem: {
      display: 'block',
      width: '100%',
      padding: '0.25rem 1rem',
      color: '#212529',
      textAlign: 'center',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      backgroundColor: 'transparent',
      border: 0,
      cursor: 'pointer',
    },
    racProfileMenu: {
      right: 0,
      left: 'auto',
    },
    racStandardIcon: {
      width: '35px',
      // marginTop: '3px',
      borderRadius: '17px',
      cursor: 'pointer',
    },
    helperIconStyle: {
      width: '24px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    racSearchPrimary: {
      // color: `${RACCOLOR.WHITE}`,
      // backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      // borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      // padding: '10px 22px 10px 40px',
      // fontFamily: 'OpenSans-semibold',
      // fontSize: '14px',
      // backgroundImage: `url(${GlobalSearchBtn})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: '15px',
      // float: 'left',
      marginTop: '17px',
      // borderRadius: '5px',
      // cursor: 'pointer',
      // textDecoration: 'none !important',
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: '15px !important',
      paddingLeft: '24px',
      paddingRight: '20px',
      fontFamily: 'OpenSans-Bold',
      fontSize: '13px',
    },
    racSearchPrimarydisabled: {
      padding: '10px 22px 10px 40px',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      backgroundImage: `url(${GlobalSearchBtn})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '15px',
      float: 'left',
      marginTop: '17px',
      color: `${RACCOLOR.WHITE}`,
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      pointerEvents: 'none',
      cursor: 'not-allowed',
      opacity: 0.65,
      borderRadius: '5px',
      textDecoration: 'none !important',
    },
    marginLeftSpacer: {
      marginLeft: '20px',
    },
    racNavMenuActive: {
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      color: `${RACCOLOR.WHITE}`,
      borderRadius: '4px',
      '&:after': {
        borderColor: `${RACCOLOR.WHITE}`,
      },
    },
    btnStore: {
      borderRadius: '4px',
      padding: '10px 15px 10px 15px',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      margin: '0 10px',
      color: '#4646fd',
      backgroundColor: '#f4f5f7',
      borderColor: '#dedfe0',
    },
    dropDownTop: {
      top: '5px !important',
    },
    displaySubmenu: {
      display: 'block',
    },
    singleStore: {
      border: 'thin solid #e3e3e3',
      borderRadius: '0.3125rem',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      marginTop: '3px !important',
      padding: '5px 8px',
      background: '#f2f2ff',
      color: '#0f0fbf',
    },
    dropdownLabelClass: {
      marginBottom: theme.typography.pxToRem(4),
    },
    textCenterSSN: {
      '& input': {
        textAlign: 'center',
      },
    },
    fontFam: {
      '& div': {
        fontFamily: 'OpenSans-semibold',
      },
    },
    racDropdownMenuTransition: {
      webkitTransition:
        'max-height 1.5s, opacity 1.5s 0.5s, visibility 0s 1.5s',
      mozTransition: 'max-height 1.5s, opacity 1.5s 0.5s, visibility 0s 1.5s',
      msTransition: 'max-height 1.5s, opacity 1.5s 0.5s, visibility 0s 1.5s',
      oTransition: 'max-height 1.5s, opacity 1.5s 0.5s, visibility 0s 1.5s',
      transition: 'max-height 1.5s, opacity 1.5s 0.5s, visibility 0s 1.5s',
      maxHeight: '0',
      display: 'block',
      overflow: 'hidden',
      opacity: '0',
      visibility: 'hidden',
      padding: '0px',
    },
    dropWidth: {
      width: '130px',
    },
    searchableDrop: {
      '& div': {
        '& div': {
          '& div': {
            color: '#0f0fbf !important',
            fontFamily: 'OpenSans-semibold',
          },
        },
      },
    },
    dropheight: {
      padding: '0px !important',
      backgroundColor: '#f2f2ff !important',
      '& input': {
        height: '15px',
        paddingRight: '0px',
        paddingLeft: '10px !important',
      },
      '& fieldset': {
        borderColor: '#e3e3e3 !important',
      },
      '& div': {
        marginRight: '5px',
      },
    },
    DropBorder: {
      backgroundColor: 'white !important',
      border: 'none !important',
    },
    dropdownitemstyle: {
      whiteSpace: 'nowrap',
      fontSize: '14px',
    },
    IconClass: {
      width: '12px !important',
    },
    textFont: {
      fontSize: '14px !important',
      paddingLeft: '17px !important',
    },
    cursorDefault: {
      cursor: 'default !important',
    },
    menuItem: {
      '&:hover': {
        background: 'none !important',
      },
    },
    menuList: {
      width: '400px',
    },
    helperList: {
      width: '250px',
    },
    btnAlign: {
      textAlign: 'center',
    },
    closeIcon: {
      width: '15px',
      float: 'right',
      outline: 'none',
      cursor: 'pointer',
    },
    helpText: {
      fontFamily: 'OpenSans-bold',
      fontSize: '18px',
    },
    racStandardInfoIcon: {
      width: '20px',
    },
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
      paddingTop: theme.typography.pxToRem(8),
    },
    datePicker: {
      paddingTop: '9px !important',
      marginBottom: '0px !important',
      '& div': {
        '& div': {
          '& fieldset': {
            borderColor: '#c4c4c4 !important',
          },

          '& input': {
            padding: '6px',

            paddingLeft: '12px',
          },
        },
      },
    },
    datePickerAddCo: {
      marginBottom: '0px !important',
      marginTop: '4px !important',
    },
    spacerPT1: {
      paddingTop: theme.typography.pxToRem(16),
    },
    ssnpadding: {
      paddingTop: '4px',
    },
    pt4: {
      paddingTop: theme.typography.pxToRem(12),
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};
