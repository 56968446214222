import React from 'react';
import { makeStyles } from '@rentacenter/racstrap';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';

export const testId = 'reportsTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '3rem',
  },
}));

export const Reports = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  return (
    <div className={classes.microAppContainer} data-testid={testId}>
      <MicroFrontend
        config={appConfig.microfrontends.reports}
        history={history}
      />
    </div>
  );
};
