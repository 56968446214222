import React, { useReducer, ReactNode, useMemo } from 'react';

import { globalReducer, initialState } from './global-reducer';
import {
  GlobalStateContext,
  GlobalDispatchContext,
  GlobalThunksContext,
} from './global-context';
import { getCurrentUserThunk, GlobalThunksContextValue } from './global-thunks';

export const GlobalContextProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  const thunks: GlobalThunksContextValue = useMemo(
    () => ({
      getCurrentUser: getCurrentUserThunk(dispatch, state),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.updatedStoreNumber]
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        <GlobalThunksContext.Provider value={thunks}>
          {props.children}
        </GlobalThunksContext.Provider>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
