/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { Dispatch } from "react";
import { Action } from "../Action";
import { getCurrentUser, getmenuandstore, getUserInfo } from "../../api/user";
import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
} from "./user/user-actions";
import { User } from "../../domain/User/User";
import { GlobalState } from "./global-reducer";
import { Auth } from "@aws-amplify/auth";
import jwtDecode from "jwt-decode";

export interface GlobalThunksContextValue {
  getCurrentUser(): Promise<void>;
}

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export const getCurrentUserThunk =
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (dispatch: Dispatch<Action>, state?: GlobalState) => async () => {
    try {
      const sessionStoreNumber = sessionStorage.getItem("storeNumber");
      dispatch(getUserAction(true));

      let userInfo: User;

      // Routing users between new menu vs old menu
      const idToken = await getJWToken();
      const decodedToken: any = jwtDecode(idToken);
      const groupName = decodedToken["custom:groupName"];
      const newMenuAllowedGroups = "_okta.allowedgroup";
      const ALL_USERS = "All_Users";
      console.log('okta group names in env', newMenuAllowedGroups, ALL_USERS);
      console.log('Group Name', groupName);
      if (
        (groupName &&
        newMenuAllowedGroups == groupName &&
        window.sessionStorage.getItem("OldMenu") != "1") ||
        window.sessionStorage.getItem("NewMenu") == "1" ||
        String(newMenuAllowedGroups) == String(ALL_USERS)
      ) {
        let decodedUserInfo: any = {
          email: state?.currentUser?.user?.email || "",
          id: state?.currentUser?.user?.id || "",
          employeeId: state?.currentUser?.user?.employeeId || "",
          firstName: state?.currentUser?.user?.firstName || "",
          lastName: state?.currentUser?.user?.lastName || "",
        };

        if (
          !state?.currentUser?.user &&
          !state?.currentUser?.user?.id &&
          !state?.currentUser?.user?.employeeId
        ) {
          decodedUserInfo = await getUserInfo();
          console.log("getUserInfo response", decodedUserInfo);
        }

        const menuPayload: any = {
          coworkerId: decodedUserInfo.employeeId,
          storesRequired: true,
          menuRequired: true,
        };
        if (state?.updatedStoreNumber || sessionStoreNumber) {
          menuPayload.menuRequest = {
            association: {
              associationCode: state?.updatedStoreNumber || sessionStoreNumber,
              associationType: "STORE",
            },
          };
        }
        console.log('reached menu call in global-thunks');
        const menuAndStoreAssignment = await getmenuandstore(menuPayload);
        console.log('menu call response global-thunks', menuAndStoreAssignment);
        // sort to the stores here
        if (
          menuAndStoreAssignment &&
          menuAndStoreAssignment.storeAssignment &&
          menuAndStoreAssignment.storeAssignment.length > 0
        ) {
          menuAndStoreAssignment.storeAssignment =
            menuAndStoreAssignment.storeAssignment.sort(
              (prev: any, next: any) =>
                prev.accountingUnitNumber > next.accountingUnitNumber ? 1 : -1
            );
        }

        if (menuAndStoreAssignment.storeAssignment.length > 0) {
          const isStoreNumberPresent =
            menuAndStoreAssignment.storeAssignment?.some(
              (el: any) => el.accountingUnitNumber == sessionStoreNumber
            );
          if (
            (!state?.updatedStoreNumber || !isStoreNumberPresent) &&
            !sessionStoreNumber
          ) {
            sessionStorage.setItem(
              "storeNumber",
              menuAndStoreAssignment.storeAssignment[0].accountingUnitNumber
            );
            sessionStorage.setItem(
              "storeDetails",
              Buffer.from(
                JSON.stringify(
                  menuAndStoreAssignment.storeAssignment[0].storeDetails
                )
              ).toString("base64")
            );
          }
        }

        userInfo = {
          ...decodedUserInfo,
          storeAssignment: menuAndStoreAssignment?.storeAssignment,
          menuProfile: menuAndStoreAssignment?.menuConfig,
          coworkerProfile: menuAndStoreAssignment?.coworkerProfile,
        };
        console.log(userInfo, 'userInfo in global-thunks');
      } else {
        userInfo = await getCurrentUser();
      }

      const user: User = userInfo;
      console.log("userInfo before dispatch", user);
      dispatch(getUserSuccessAction(user));
    } catch (error: any) {
      dispatch(getUserErrorAction(error));
    }
  };
