import React, { useCallback, useMemo } from 'react';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
import { makeStyles, Grid, RACButton } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { useAuthContext } from '../../context/auth/auth-context';

export const unauthenticatedTestId = 'unauthenticatedTestId';

const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  racColMD12: {
    flex: '0 0 auto',
    width: '100%',
    margin: '0px',
  },
  racColMD4: {
    flex: '0 0 auto',
    width: '26.3333333333%',
  },
  mAuto: {
    margin: 'auto',
  },
  loginButton: {
    backgroundColor: '#2179FE',
    width: '100%',
    fontFamily: 'OpenSans-semibold',
    '&:hover': {
      backgroundColor: '#2179FE',
    },
  },
  w100: {
    width: '100%',
  },
  bgStyles: {
    backgroundColor: '#ecf0f3',
    height: '100vh',
    position: 'relative',
    paddingTop: '150px',
  },
  bgColor: {
    padding: theme.typography.pxToRem(40),
    backgroundColor: 'white',
    borderRadius: theme.typography.pxToRem(10),
    width: '350px',
  },
  paraText: {
    fontSize: theme.typography.pxToRem(17),
    textAlign: 'center',
    color: '#4f5778',
    fontFamily: 'OpenSans-semibold',
    marginTop: theme.typography.pxToRem(60),
    marginBottom: theme.typography.pxToRem(60),
  },
  imageCenter: {
    width: '100%',
    textAlign: 'center',
  },
}));

export const Unauthenticated = () => {
  const { login, error } = useAuthContext();
  const classes = useClasses();

  const handleClick = useCallback(() => {
    if (login) {
      login();
    }
  }, [login]);

  return useMemo(
    () => (
      <Grid
        data-testid={unauthenticatedTestId}
        classes={{ root: clsx(classes.racColMD12, classes.bgStyles) }}
      >
        <Grid
          classes={{
            root: clsx(classes.racColMD4, classes.mAuto, classes.bgColor),
          }}
        >
          <RACLogo className={classes.imageCenter}></RACLogo>
          {!error ? (
            <p className={classes.paraText}>
              Use your Rent-A-Center credentials to sign in
            </p>
          ) : (
            <p
              className={classes.paraText}
            >{`The following error occured: ${error}`}</p>
          )}
          <RACButton
            variant="contained"
            color="primary"
            onClick={handleClick}
            data-testid="signin"
            style={{ textTransform: 'none' }}
            className={classes.loginButton}
          >
            Sign-in with single Sign-On
          </RACButton>
        </Grid>
      </Grid>
    ),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [error, handleClick]
  );
};
