import React, { useContext } from 'react';
import { makeStyles } from '@rentacenter/racstrap';

import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';

export const testId = 'pricingTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '3rem',
  },
}));

export const Pricing = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);

  const classes = useStyles();
  return (
    <div className={classes.microAppContainer} data-testid={testId}>
      <MicroFrontend
        config={appConfig.microfrontends.pricing}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
