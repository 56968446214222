import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as RACLogoImg } from '../../../assets/images/RACUpdatedLogoNew.svg';
import { headerStyles } from '../../../headerStyles';

function RACLogo(props: any) {
  const classes = headerStyles();
  return (
    <Link to={props.initialPath} className={classes.racNavBarBrand}>
      <RACLogoImg className={classes.racLogoResponsive}></RACLogoImg>
    </Link>
  );
}

export default RACLogo;
