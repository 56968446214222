/* eslint-disable no-console */
import React from 'react';
import { Router } from 'react-router';

import {
  RACThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
// import clsx from 'clsx';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { AuthContextProvider } from '../context/auth/AuthContextProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
// import '../styles/global.css';
// import { createBrowserHistory } from 'history';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { createBrowserHistory } = require('history');

export const appTestId = 'appTestId';
const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodmenu',
  seed: 'RAC-HEADER-',
});

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

export const App = () => {
  const enableLogs = window.sessionStorage.getItem('enableLogs');
  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <ErrorBoundary>
            <Router history={history}>
              <AuthContextProvider>
                <AuthGuard />
              </AuthContextProvider>
            </Router>
          </ErrorBoundary>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
