import { createContext, useContext } from 'react';

import { GenericObject } from '../../utils/GenericObject';

export interface AuthContextValue {
  userTokenData?: GenericObject;
  triedToFetchTheUser: boolean;
  error?: string;
  login?: () => void;
  logout?: () => void;
}

export const AuthContext = createContext<AuthContextValue>({
  triedToFetchTheUser: false,
});

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);
