import { Action } from '../../Action';

export enum StoreActionType {
  STORE_CHANGED = 'STORE_CHANGED',
}

export interface GetStoreChangeActionType extends Action {
  type: StoreActionType.STORE_CHANGED;
  payload: {
    storeNumber: string;
  };
}
export const GetStoreChangeAction = (
  storeNumber: string
): GetStoreChangeActionType => ({
  type: StoreActionType.STORE_CHANGED,
  payload: { storeNumber },
});

// export type CurrentuserAction = GetStoreChangeActionType;
