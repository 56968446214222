import React from 'react';
import { Grid, ListItem } from '@rentacenter/racstrap';
import { headerStyles } from '../../../headerStyles';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';

// Need to work on when Menu/subMenu clicked the search Grid should have to hidden
function Menu(props: any) {
  const classes = headerStyles();
  return (
    <ListItem
      key={props.index}
      className={`${
        props.subMenuClicked ? classes.racNavBarNone : classes.racNavBar
      }`}
    >
      {props.userNavElements.appComponents.length == 0 ? (
        <NavLink
          id={props.index.toString()}
          activeClassName={classes.racNavMenuActive}
          className={classes.racNavMenu}
          to={props.userNavElements.path.toLowerCase()}
        >
          {props.userNavElements.app}
        </NavLink>
      ) : (
        <Grid
          id={props.index.toString()}
          className={`${classes.racDropdownToggle} ${
            props.activeAppName?.toLowerCase() ==
            props.userNavElements.app.toLowerCase()
              ? classes.racNavMenuActive
              : null
          }`}
        >
          {props.userNavElements.app}
        </Grid>
      )}
      {props.userNavElements.appComponents.length > 0 ? (
        <Grid
          id={props.index.toString()}
          classes={{
            root: clsx(classes.racDropdownMenu, classes.racMenuContent),
          }}
          className={clsx({
            [classes.displaySubmenu]:
              props.SubMenuActive === props.index.toString(),
          })}
          aria-labelledby="navbarpayment"
        >
          <Grid className={classes.racMegaMenu}>
            <Grid container>
              {props.userNavElements.appComponents.map((el: any) => {
                const categoryLength =
                  props.userNavElements.appComponents.length;
                const submenuLength = el.appSubComponents.length;
                return (
                  <Grid
                    key=""
                    className={clsx({
                      [classes.racColMD12]: categoryLength <= 1,
                      [classes.racColMD6]:
                        categoryLength >= 2 && submenuLength <= 2,
                      [classes.racColMD2]:
                        categoryLength == 4 && submenuLength == 3,
                      [classes.racColMD4]:
                        categoryLength == 4 && submenuLength == 6,
                      [classes.racColMD3]:
                        categoryLength == 4 && submenuLength == 2,
                      [classes.racColMD7]:
                        categoryLength >= 2 && submenuLength >= 3,
                    })}
                  >
                    <span className={classes.menuSubHeader}>
                      {el.appComponent}
                    </span>

                    <Grid container className="" style={{ marginTop: '10px' }}>
                      {el.appSubComponents.map((val: any, ind: any) => {
                        return (
                          <Grid
                            item
                            key=""
                            className={clsx({
                              [classes.racColMD3]: categoryLength <= 1,
                              [classes.racColMD6]:
                                categoryLength >= 2 && submenuLength >= 2,
                              [classes.racColMD12]:
                                categoryLength == 4 && submenuLength == 3,
                              [classes.racColMD6]:
                                categoryLength == 4 && submenuLength == 6,
                              [classes.racColMD12]:
                                categoryLength == 4 && submenuLength == 2,
                              [classes.racColMD4]:
                                categoryLength >= 2 && submenuLength >= 3,
                            })}
                          >
                            <Link
                              id={ind.toString()}
                              className={classes.navMenu}
                              to={val.path}
                            >
                              {val.appSubComponenet}
                            </Link>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </ListItem>
  );
}
export default Menu;
