import { makeStyles } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';

export const acimaPoTestId = 'acimaPoTestId';
//Added Command need to be removed
const useStyles = makeStyles(() => ({
  microAppContainer: {
    paddingTop: '3rem',
  },
}));

export const AcimaPo = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  const classes = useStyles();
  return (
    <div data-testid={acimaPoTestId} className={classes.microAppContainer}>
      <MicroFrontend
        config={appConfig.microfrontends.acimapo}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
