export enum StaticContent {
  Hello = 'Hello',
  Menu = 'Menu',
  NoPermissionMessage = "I'm sorry but you currently don't have the necessary permissions to access this page.",
  ContactAdministratorMessage = 'Please contact an administrator if you feel this is an error.',
  NoProductMessage = 'No products available.',
  ContactSupportMessage = 'Please contact the support team',
}

export enum ActionTitle {
  Logout = 'Logout',
  SignOut = 'Sign out',
}

export enum PageTitle {
  PastDueList = 'Past Due List',
}
