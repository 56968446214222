import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@rentacenter/racstrap';
import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';
export const testId = 'customerTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '3rem',
  },
}));

export const Customer = ({ history }: RouteComponentProps) => {
  const containerData = useContext(ContainerContext);
  const classes = useStyles();
  return (
    <div className={classes.microAppContainer} data-testid={testId}>
      <MicroFrontend
        config={appConfig.microfrontends.customer}
        history={history}
        customProps={containerData}
      />
    </div>
  );
};
