import { GetStoreChangeActionType, StoreActionType } from './store-action';

export const storeReducer = (
  state: string,
  action: GetStoreChangeActionType
): any => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case StoreActionType.STORE_CHANGED:
      return action.payload.storeNumber;
    default:
      return state;
  }
};
