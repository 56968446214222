import { makeStyles } from '@rentacenter/racstrap';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';

export const testId = 'mPOTestId';

const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '5rem',
  },
}));

export const MPO = ({ history }: RouteComponentProps) => {
  const classes = useStyles();

  return (
    <div data-testid={testId} className={classes.microAppContainer}>
      <MicroFrontend config={appConfig.microfrontends.mpo} history={history} />
    </div>
  );
};
