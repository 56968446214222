import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsFullscreen(location.hash === '#fullscreen');
  }, [location]);

  return isFullscreen;
};
