/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { makeStyles } from '@rentacenter/racstrap';
import { RouteComponentProps } from 'react-router-dom';

import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';
export const testId = 'reportsTestId';
const useStyles = makeStyles(() => ({
    microAppContainer: {
        marginTop: '3rem',
    },
}));

export const Reporting = ({ history }: RouteComponentProps) => {
    const containerData = useContext(ContainerContext);
    const classes = useStyles();
    return (
        <div className={classes.microAppContainer} data-testid={testId}>
            <MicroFrontend
                config={appConfig.microfrontends.reporting}
                history={history}
                customProps={containerData}
            />
        </div>
    );
};
