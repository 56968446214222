import React, { useEffect } from 'react';

import { useGlobalThunksContext } from '../context/global/global-context';

export const UserFetcher = () => {
  const { getCurrentUser } = useGlobalThunksContext();

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return <></>;
};
